import { useMemo, useEffect, useState } from "react";
import { useOutletContext } from "react-router";

import { StyledPage, StyledPageContent } from "../styled";
import { StyledConstraintsArea } from "./styles";
import useConstraintsData from "../../layout/WorkbookLayout/useConstraintsData";
import ConstraintsSideArea from "./content/ConstraintsSideArea";
import ConstraintsContent from "./content/ConstraintsContent";
import { PROGRESS_STATES } from "../../constants/constraints";
import ConstraintsContext from "./content/context";

const Constraints = () => {
  const { portfolioList, getWorkbookResults, portfolioBuildProgress } =
    useOutletContext();

  const constraintsDraftData = useConstraintsData({});
  const {
    selectedPortfolio,
    isConstraintsInit,
    showSkeleton,
    isLoading,
    setSelectedPortfolio,
    setShowSkeleton,
    setIsLoading,
  } = constraintsDraftData;

  const status = portfolioBuildProgress?.status;
  const isDisabled = status !== PROGRESS_STATES.draft;

  const [showSideMenu, setShowSideMenu] = useState(null);
  const [constraintsContext, setConstraintsContext] = useState({
    constraints: {},
    currentPortfolioUUID: "",
  });
  const isMultiPortfolio = portfolioList.length > 1;

  useEffect(() => {
    setShowSideMenu(portfolioList?.length > 1);

    setConstraintsContext({
      ...constraintsContext,
      currentPortfolioUUID: selectedPortfolio?.uuid,
      constraints: portfolioList.reduce((acc, portfolio) => {
        return {
          ...acc,
          [portfolio.uuid]: { isEnabled: portfolio.isEnabled },
        };
      }, {}),
    });
  }, [portfolioList]);

  useEffect(() => {
    setConstraintsContext({
      ...constraintsContext,
      currentPortfolioUUID: selectedPortfolio?.uuid,
    });
  }, [selectedPortfolio?.uuid]);

  const constraintsContextValue = useMemo(
    () => ({
      constraintsContext,
      setConstraintsContext,
    }),
    [constraintsContext],
  );

  return (
    <StyledPage grow>
      <StyledPageContent grow $noPadding>
        <StyledConstraintsArea showSideMenu={showSideMenu}>
          <ConstraintsContext.Provider value={constraintsContextValue}>
            <ConstraintsSideArea
              selectedPortfolio={selectedPortfolio}
              portfolioList={portfolioList}
              setSelectedPortfolio={setSelectedPortfolio}
              setShowSkeleton={setShowSkeleton}
              showSideMenu={showSideMenu}
            />
            <ConstraintsContent
              constraintsDraftData={constraintsDraftData}
              isMultiPortfolio={isMultiPortfolio}
              getWorkbookResults={getWorkbookResults}
              setIsLoading={setIsLoading}
              loading={isLoading || (isConstraintsInit && showSkeleton)}
              isDisabled={isDisabled}
            />
          </ConstraintsContext.Provider>
        </StyledConstraintsArea>
      </StyledPageContent>
    </StyledPage>
  );
};

export default Constraints;
