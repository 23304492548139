import { useCallback } from "react";
import { isEmpty, lowerCase, snakeCase } from "lodash";
import filterTypes from "../../../constants/filterTypes";
import {
  MultipleChoiceFilter,
  RangePickerFilter,
  TextFilter,
} from "../../../components/Table/Filters";
import useTableFilters from "./useTableFilters";
import { getFilterType, getDefaultValue, getChoices } from "../utils";
import { StyledTableColHeader } from "../../../components/Table/styles";

const useTableFiltersMapper = (listParams, detailSchema) => {
  const {
    handleDatePicketChange,
    handleResetFilterValue,
    handleFilterChange,
    showResetBtn,
    setShowResetBtn,
    clearFilters,
    filters,
    handleSorter,
  } = useTableFilters();
  const mapToFilters = useCallback(
    (res, key) => {
      const result = { ...res };
      const filterKey = snakeCase(key);
      const filterParams = listParams?.[filterKey] ?? null;
      const filterValue = filters[filterKey];
      const filterType = getFilterType(detailSchema[key] ?? {});
      const { title } = result;
      const filteredValue = filters[snakeCase(lowerCase(title))];

      if (!filterParams) return result;

      if (filterParams.sortable) {
        result.sorter = true;
      }

      result.filteredValue = null;

      if (filters[snakeCase(lowerCase(title))]) {
        result.title = <StyledTableColHeader>{title}</StyledTableColHeader>;
        result.filteredValue = [filteredValue];
      }

      if (isEmpty(filters)) {
        result.sortOrder = null;
      }

      if (filters.order_by && filters.order_by.includes(filterKey)) {
        result.sortOrder = filters.order_by[0] === "-" ? "descend" : "ascend";
      }

      if (filterType === filterTypes.text) {
        result.filterDropdown = ({ close }) => (
          <TextFilter
            onApply={(_, value) => handleFilterChange(filterKey, value)}
            onReset={() => handleResetFilterValue(filterKey)}
            defaultValue={filterValue}
            close={close}
          />
        );
      }

      if (filterType === filterTypes.dateRange) {
        const defaultDates = getDefaultValue(filterValue);

        result.filterDropdown = ({ close }) => (
          <RangePickerFilter
            onChange={(_, value) => handleDatePicketChange(filterKey, value)}
            onReset={() => handleResetFilterValue(filterKey)}
            defaultDates={defaultDates}
            close={close}
          />
        );
      }

      if (
        filterType === filterTypes.multipleChoice ||
        filterType === filterTypes.boolean
      ) {
        const defaultCheckedValues = getDefaultValue(filterValue);
        const choices = getChoices(filterParams.choices);

        result.filterDropdown = ({ close }) => {
          return (
            <MultipleChoiceFilter
              choices={choices}
              close={close}
              onApply={(_, value) => handleFilterChange(filterKey, value)}
              onReset={() => handleResetFilterValue(filterKey)}
              defaultCheckedValues={defaultCheckedValues}
            />
          );
        };
      }

      return result;
    },
    [
      detailSchema,
      filters,
      handleDatePicketChange,
      handleFilterChange,
      handleResetFilterValue,
      listParams,
    ],
  );

  const handleTableChange = useCallback(
    (pagination, filter, sorter) => {
      const filterKey = snakeCase(sorter.field);

      handleSorter(filterKey, sorter.order);
    },
    [handleSorter],
  );

  return {
    mapToFilters,
    handleTableChange,
    showResetBtn,
    clearFilters,
    setShowResetBtn,
    handleResetFilterValue,
  };
};

export default useTableFiltersMapper;
