import { forwardRef } from "react";
import PropTypes from "prop-types";
import { StyledInput, StyledInputWrapper } from "./styles";

const TextInput = forwardRef(({ ...props }, ref) => (
  <StyledInputWrapper>
    <StyledInput {...props} ref={ref} />
  </StyledInputWrapper>
));

TextInput.displayName = "TextInput";

TextInput.propTypes = {
  status: PropTypes.string,
};

export default TextInput;
