import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import { StyledCardsIcon } from "../../components/Icons";
import StyledButton from "../../components/Buttons/Button.styled";
import {
  StyledPageTitle,
  StyledWelcomePageContent,
} from "../Auth/WelcomePage.styled";
import { StyledBodyText } from "../../components/Typography";
import { StyledPageTextContainer } from "../styled";

const EmptyData = ({ title, description = "" }) => {
  const navigate = useNavigate();

  return (
    <StyledWelcomePageContent>
      <StyledCardsIcon />
      <StyledPageTextContainer>
        <StyledPageTitle>{title}</StyledPageTitle>
        {description && <StyledBodyText>{description}</StyledBodyText>}
      </StyledPageTextContainer>
      <StyledButton $autoWidth onClick={() => navigate(-1)}>
        go back
      </StyledButton>
    </StyledWelcomePageContent>
  );
};

EmptyData.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};

export default EmptyData;
