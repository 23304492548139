import { useCallback } from "react";
import PropTypes from "prop-types";
import NumberInput from "./NumberInput";
import { StyledRangeInput } from "./styles";

const NumberInputRange = ({
  name,
  title = "",
  type = "number",
  fromInputAttrs = {
    defaultValue: null,
    min: null,
    max: null,
  },
  toInputAttrs = {
    defaultValue: null,
    min: null,
    max: null,
  },
  disabled = false,
  onChange,
  checkIsError,
  width,
  ...props
}) => {
  const handleChange = useCallback(
    (fieldName, attributeName, value) => {
      onChange(fieldName, attributeName, value);
    },
    [onChange],
  );

  return (
    <StyledRangeInput width={width}>
      <NumberInput
        type={type}
        title={title}
        disabled={disabled}
        name={name}
        attributeName="min"
        onChange={handleChange}
        showError={checkIsError("min")}
        {...fromInputAttrs}
        {...props}
      />
      <span>-</span>
      <NumberInput
        type={type}
        title={title}
        disabled={disabled}
        name={name}
        attributeName="max"
        showError={checkIsError("max")}
        onChange={handleChange}
        {...toInputAttrs}
        {...props}
      />
    </StyledRangeInput>
  );
};

NumberInputRange.propTypes = {
  name: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  fromInputAttrs: PropTypes.shape(),
  toInputAttrs: PropTypes.shape(),
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  checkIsError: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default NumberInputRange;
