import styled, { css } from "styled-components/macro";
import { Button } from "antd";
import ButtonComponent from "./Button";
import {
  white,
  accentRed,
  gray80,
  gray30,
  gray40,
  gray90,
  gray20,
  gray60,
  blue20,
  blue90,
  blue100,
} from "../../constants/colors";

export const StyledTextButton = styled(Button)`
  display: flex;
  align-items: center;
  color: ${gray80};
  transition: color 0.5s ease;
  font-weight: 600;

  font-size: 14px;
  line-height: 20px;
  height: ${({ $autoHeight }) => ($autoHeight ? "auto" : "40px")};
  text-transform: capitalize;

  svg {
    flex: 0 0 auto;
    margin-right: 0;
    fill: currentColor;
    path {
      fill: currentColor;
      ${props =>
        props.$transparentIcon &&
        css`
          fill: transparent;
        `}
    }
  }

  &.ant-btn > span + .anticon {
    margin-inline-start: 0;
  }

  &&:disabled {
    opacity: 0.5;
  }

  ${props =>
    props.$bold &&
    css`
      font-weight: 700;
    `}

  ${props =>
    props.$primary &&
    css`
      color: ${blue90};
      &&:disabled {
        color: ${blue90};
        opacity: 0.5;
      }
    `}
  
  ${props =>
    props.$error &&
    css`
      color: ${accentRed};
      &&:disabled {
        color: ${accentRed};
        opacity: 0.5;
      }
    `}

  ${props =>
    props.$normal &&
    css`
      text-transform: none;
      font-weight: 500;
    `}

  ${props =>
    props.$iconOnly &&
    css`
      align-items: center;
      justify-content: center;

      svg {
        margin-right: 0;
      }
    `};

  ${props =>
    props.$transparent &&
    css`
      background-color: transparent;
      box-shadow: none;
      &&:disabled {
        background-color: transparent;
        box-shadow: none;
      }
    `}

  padding: ${({ $noPadding }) => ($noPadding ? "unset" : "10px 20px")};

  &.ant-btn-circle {
    justify-content: center;
    align-items: center;
    border: none;

    svg {
      margin: 0;
    }
  }

  &:focus,
  &:hover {
    color: ${gray80} !important;

    ${props =>
      props.$primary &&
      css`
        color: ${blue90} !important;
        background-color: transparent !important;
      `}
  }
`;

export const StyledTextPrimaryButton = styled(StyledTextButton)`
  color: ${blue90};
  &:hover {
    color: ${blue90};
  }
`;

export const StyledBackButton = styled(StyledTextButton)`
  margin-bottom: 1rem;

  ${props =>
    props.$nopadding &&
    css`
      padding: 0;
    `}

  &:hover {
    background-color: inherit !important;
  }
`;

export const StyledBackText = styled.span`
  font-weight: 600;
`;

const StyledPrimary = css`
  border-color: ${blue90};
  background-color: ${blue90} !important;
  color: ${white};

  :hover,
  :focus,
  :active {
    border-color: ${blue100};
    background-color: ${blue100} !important;
    color: ${white} !important;
  }

  :disabled {
    border-color: ${blue20};
    background-color: ${blue20} !important;
    color: ${white} !important;
  }

  > svg path {
    fill: currentColor;
  }
`;

const StyledSecondary = css`
  border-color: ${gray30};
  background-color: ${gray30} !important;
  color: ${gray80};

  :hover,
  :focus,
  :active {
    border-color: ${gray40};
    background-color: ${gray40} !important;
    color: ${gray90};
  }

  :disabled {
    border-color: ${gray20};
    background-color: ${gray20} !important;
    color: ${gray60};
  }
`;

const typeSwitcher = buttonType => {
  switch (buttonType) {
    case "primary":
      return StyledPrimary;
    case "secondary":
      return StyledSecondary;
    default:
      return StyledPrimary;
  }
};

const StyledButton = styled(ButtonComponent)`
  min-width: ${({ $autoWidth }) => ($autoWidth ? "auto" : "180px")};
  height: ${({ $autoHeight }) => ($autoHeight ? "auto" : "40px")};
  padding: ${({ $noPadding }) => ($noPadding ? "unset" : "10px 20px")};
  border-radius: 2px;

  font-size: ${({ fontSize }) => fontSize ?? "14"}px !important;
  font-weight: ${({ weight, $normal }) => weight || ($normal ? "500" : "600")};
  line-height: 20px;
  text-align: center;
  text-transform: ${({ $lowercase }) => ($lowercase ? "unset" : "uppercase")};
  transition: all 0.3s ease;
  box-shadow: unset;
  box-sizing: border-box;
  margin: ${({ margin }) => margin ?? "0"};
  text-transform: capitalize;

  &:after {
    animation: 0s;
  }

  > div {
    display: flex;
    align-items: center;
    margin: 0 auto;
  }

  :focus,
  :active {
    background: inherit;
    border-color: inherit;
    text-decoration: inherit;
    color: initial;
  }

  > svg {
    flex: 0 0 auto;
    margin-right: 0.5rem;
  }

  ${({ type }) => typeSwitcher(type)};
  ${({ color }) => color && `color: ${color};`}
  ${({ $height }) => $height && `height : ${$height}px;`}
  ${({ $width }) => $width && `width : ${$width}px;`}
  ${({ $padding }) => $padding && `padding : ${$padding};`}
`;

export default StyledButton;
