import { useCallback, useEffect, useMemo, useState } from "react";
import formatISO from "date-fns/formatISO";
import omit from "lodash/omit";
import { isEmpty } from "lodash";
import useQuery from "../../../hooks/useQuery";
import { getSortSignDirection } from "../utils";

const notClearableParams = ["page"];

const useTableFilters = () => {
  const { query, setQuery, deleteQuery } = useQuery();
  const filters = useMemo(() => {
    return Object.fromEntries(query.entries());
  }, [query]);
  const [showResetBtn, setShowResetBtn] = useState(() => {
    return !!Object.keys(filters).filter(
      filter => !notClearableParams.includes(filter),
    ).length;
  });

  useEffect(() => {
    if (!isEmpty(omit(filters, notClearableParams))) {
      setShowResetBtn(true);
    } else {
      setShowResetBtn(false);
    }
  }, [filters]);

  const handleCreateDateFilter = useCallback(datesObj => {
    const datesObjDateFrom = new Date(datesObj?.dateFrom);
    datesObjDateFrom.setHours(0);
    datesObjDateFrom.setMinutes(0);
    datesObjDateFrom.setSeconds(0);
    const dateFrom = formatISO(new Date(datesObjDateFrom));

    const datesObjDateTo = new Date(datesObj?.dateTo);
    datesObjDateTo.setHours(23);
    datesObjDateTo.setMinutes(59);
    datesObjDateTo.setSeconds(59);
    const dateTo = formatISO(new Date(datesObjDateTo));
    return [`<${dateTo}`, `>${dateFrom}`];
  }, []);

  const handleResetFilterValue = useCallback(
    key => {
      const filtersKeys = Object.keys(filters);
      if (filtersKeys?.length === 1 && filtersKeys[0] === key) {
        setShowResetBtn(false);
      }
      deleteQuery(key);
    },
    [deleteQuery, filters],
  );

  const handleDatePicketChange = useCallback(
    (key, value) => {
      handleResetFilterValue(key);

      const dateObj = {
        dateFrom: value[0],
        dateTo: value[1],
      };
      const filter = handleCreateDateFilter(dateObj);
      const newFilter = typeof filter === "string" ? filter.split(",") : filter;
      setQuery(key, newFilter, { replace: false });
      deleteQuery("page");
    },
    [deleteQuery, handleCreateDateFilter, handleResetFilterValue, setQuery],
  );

  const handleFilterChange = useCallback(
    (key, value) => {
      if (value.length) {
        handleResetFilterValue(key);
        setQuery(key, value, { replace: false });
      } else {
        deleteQuery(key, { replace: false });
      }
      deleteQuery("page");
    },
    [deleteQuery, handleResetFilterValue, setQuery],
  );

  const clearFilters = useCallback(() => {
    setShowResetBtn(false);

    const deletedKeys = [];

    query.forEach((value, key) => {
      if (!notClearableParams.includes(key)) {
        deletedKeys.push(key);
      }
    });

    deleteQuery(deletedKeys, { replace: false });
  }, [deleteQuery, query]);

  const handleSorter = useCallback(
    (key, sortName) => {
      const sign = getSortSignDirection(sortName);

      if (sign === null) {
        deleteQuery("order_by", { replace: false });
      } else {
        const value = `${sign}${key}`;

        setQuery("order_by", value, { replace: false });
      }
    },
    [deleteQuery, setQuery],
  );

  return {
    handleDatePicketChange,
    handleResetFilterValue,
    handleFilterChange,
    showResetBtn,
    setShowResetBtn,
    filters,
    clearFilters,
    handleSorter,
  };
};

export default useTableFilters;
