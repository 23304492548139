import { useCallback, useState } from "react";
import { useParams } from "react-router";
import PropTypes from "prop-types";

import { sortBy } from "lodash";
import { getPortfoliosData } from "../../../api/endpoints/portfolios";
import { handleErrorMessages } from "../../../utils/messages";

const useDataId = ({ workbookId, handleErrorRequests, workbookResults }) => {
  const { result_id: resultId } = useParams();
  const [portfolioList, setPortfolioList] = useState([]);
  const [currentPortfolio, setCurrentPortfolio] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const firstResultUuid = workbookResults[0]?.uuid;
  const resId = resultId || firstResultUuid || null;

  const refreshPortfolioData = useCallback(
    handleLoading => {
      if (!workbookId) return;

      const refreshData = async () => {
        setIsLoading(true);
        try {
          let portfolios;
          let portfolio;

          if (resId) {
            portfolios = await getPortfoliosData(resId);
          }
          if (handleLoading) {
            handleLoading();
          }
          return {
            resultUid: resId || null,
            allPortfolios: portfolios,
            portfolio,
          };
        } catch (error) {
          handleErrorRequests(error);
          if (handleLoading) {
            handleLoading();
          }
          return {};
        } finally {
          setIsLoading(false);
        }
      };

      const refreshStates = ({ allPortfolios, portfolio }) => {
        const sortedPortfolios = sortBy(allPortfolios || [], "name");
        setPortfolioList(sortedPortfolios);
        setCurrentPortfolio(portfolio);
      };

      refreshData().then(refreshStates).catch(handleErrorMessages);
    },
    [handleErrorRequests, resId, workbookId],
  );

  return {
    resultUUID: resultId,
    currentPortfolio,
    refreshPortfolioData,
    portfolioList,
    setPortfolioList,
    isLoading,
  };
};

useDataId.propTypes = {
  workbookId: PropTypes.string,
  resultId: PropTypes.string,
  handleErrorRequests: PropTypes.func,
};

export default useDataId;
