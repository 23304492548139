import PropTypes from "prop-types";
import ModalDialog from "../../../components/Modals/ModalDialog";
import StyledButton from "../../../components/Buttons/Button.styled";
import {
  StyledModalPrimaryText,
  StyledModalButtonsContainer,
} from "../../../components/Modals/ModalDialog.styled";

const DeleteModal = ({
  isOpen = false,
  onClose,
  onDelete,
  title = null,
  subtitle = null,
}) => {
  return (
    <ModalDialog isOpen={isOpen} onClose={onClose} title={title}>
      <div>
        <StyledModalPrimaryText>{subtitle}</StyledModalPrimaryText>
      </div>
      <StyledModalButtonsContainer>
        <StyledButton onClick={onClose} type="secondary" $autoWidth>
          Cancel
        </StyledButton>
        <StyledButton
          data-cy="delete-workbook-button"
          key="submit"
          type="primary"
          onClick={onDelete}
          $autoWidth
        >
          Remove
        </StyledButton>
      </StyledModalButtonsContainer>
    </ModalDialog>
  );
};

DeleteModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.node,
};

export default DeleteModal;
