import { useCallback } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { isArray } from "lodash";

const useQuery = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const navigateWithSearchQuery = useCallback(
    (options = { replace: true }) => {
      navigate(
        {
          pathname: location.pathname,
          search: query.toString(),
        },
        options,
      );
    },
    [location.pathname, navigate, query],
  );

  const setQuery = useCallback(
    (name, value, options = { replace: true }) => {
      if (Array.isArray(value)) {
        value.forEach(val => query.append(name, val));
      } else {
        query.set(name, value);
      }

      navigateWithSearchQuery(options);
    },
    [navigateWithSearchQuery, query],
  );

  const deleteQuery = useCallback(
    (names, options = { replace: true }) => {
      if (isArray(names)) {
        names.forEach(name => query.delete(name));
      } else {
        query.delete(names);
      }

      navigateWithSearchQuery(options);
    },
    [navigateWithSearchQuery, query],
  );

  return {
    query,
    setQuery,
    deleteQuery,
  };
};

export default useQuery;
