import { useCallback, useEffect, useMemo, useState } from "react";
import { Skeleton, Tooltip } from "antd";
import PropTypes from "prop-types";
import { isEmpty, omit } from "lodash";
import {
  StyledConstraintsBodyArea,
  StyledWrapper,
  StyledConstraintsCardTitle,
} from "../styles";
import {
  StyledPageSection,
  StyledPageSectionActions,
  StyledPageSectionHeader,
} from "../../styled";
import { StyledTextButton } from "../../../components/Buttons/Button.styled";
import { StyledCopyIcon, StyledListIcon } from "../../../components/Icons";
import ObjectiveSelect from "./ObjectiveSelect";
import {
  CONSTRAINTS_CATEGORIES,
  CONSTRAINTS_CATEGORIES_ORDER,
} from "../../../constants/objectives";
import { StyledCardActions } from "../../../components/Card/styles";
import ConstraintsList from "./ConstraintsList";
import ToggleInput from "../../../components/Form/Toggle";
import PortfolioListModal from "./PortfolioListModal";
import ConfirmCopyModal from "./ConfirmCopyModal";
import ConstraintsTitle from "./ConstraintsTitle";

const ConstraintsContent = ({
  constraintsDraftData,
  isMultiPortfolio,
  getWorkbookResults,
  loading = false,
  isDisabled = false,
  setIsLoading = () => {},
}) => {
  const {
    constraintsByCategory = {},
    selectedConstraints,
    hiddenConstraints,
    objective,
    newConstraints,
    onToggleSellReplace,
    selectedPortfolio,
    isSellReplaceEnabled,
    setIsSellReplaceEnabled,
    isConstraintsInit,
  } = constraintsDraftData;

  const [selectFromOpen, setSelectFromOpen] = useState(false);
  const [confirmCopyOpen, setConfirmCopyOpen] = useState(false);
  const [attributeError, setAttributeError] = useState([]);

  const openSelectConstraintFromModal = useCallback(() => {
    setSelectFromOpen(true);
  }, [setSelectFromOpen]);

  const closeSelectConstraintFromModal = useCallback(() => {
    setSelectFromOpen(false);
  }, [setSelectFromOpen]);

  const isSimple = key => {
    return [
      CONSTRAINTS_CATEGORIES.SETTINGS,
      CONSTRAINTS_CATEGORIES.SELL_REPLACE,
    ].includes(key);
  };

  const canDelete = key => {
    return [
      CONSTRAINTS_CATEGORIES.CONSTRAINTS,
      CONSTRAINTS_CATEGORIES.COMPLIANCE_TESTS,
    ].includes(key);
  };

  const getOnlyVisible = useCallback(
    key => {
      if (canDelete(key)) {
        return selectedConstraints[key];
      }

      return constraintsByCategory[key];
    },
    [selectedConstraints, constraintsByCategory],
  );

  const getConstraints = useCallback(
    key => {
      const constraints = getOnlyVisible(key);

      if (key === CONSTRAINTS_CATEGORIES.SETTINGS) {
        return omit(constraints, ["is_enabled"]);
      }

      return constraints;
    },
    [getOnlyVisible],
  );

  const handleError = error => {
    const errors = [...attributeError];
    error.detail.forEach(item => {
      const errorLocation = item.loc;
      const [, categoryName, fieldName, attributeNameError] = errorLocation;
      errors.push({
        valueFieldName: fieldName,
        attributeNameError,
        categoryName,
      });
    });
    setAttributeError(errors);
  };

  const handleToggleSellReplace = useCallback(
    isEnabled => {
      if (isSellReplaceEnabled !== isEnabled) {
        onToggleSellReplace(
          isEnabled,
          () => {
            setIsSellReplaceEnabled(isEnabled);
            if (getWorkbookResults) {
              getWorkbookResults({ page: 1 });
            }
          },
          handleError,
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isSellReplaceEnabled,
      onToggleSellReplace,
      setIsSellReplaceEnabled,
      getWorkbookResults,
    ],
  );

  const getActions = useCallback(
    key =>
      [CONSTRAINTS_CATEGORIES.SELL_REPLACE].includes(key) && (
        <ToggleInput
          label="Enable Options"
          value={isSellReplaceEnabled}
          dataCy="enable-sell-replace"
          onChange={handleToggleSellReplace}
          disabled={isDisabled}
        />
      ),
    [handleToggleSellReplace, isSellReplaceEnabled, isDisabled],
  );

  const isEnabledSetting = useMemo(() => {
    return getOnlyVisible(CONSTRAINTS_CATEGORIES.SETTINGS)?.is_enabled ?? {};
  }, [getOnlyVisible]);

  useEffect(() => {
    if (selectedPortfolio?.name === "single" && !selectedPortfolio?.isEnabled) {
      constraintsDraftData.setSelectedPortfolio(prevState => ({
        ...prevState,
        isEnabled: true,
      }));
      constraintsDraftData.onSwitchItem({
        key: "is_enabled",
        switchArg: true,
      });
    }
  }, [
    constraintsDraftData,
    selectedPortfolio.isEnabled,
    selectedPortfolio.name,
  ]);

  return (
    <StyledConstraintsBodyArea>
      <StyledPageSectionHeader>
        {!isEmpty(selectedPortfolio) && selectedPortfolio.name !== "single" && (
          <ConstraintsTitle
            onSwitchItem={constraintsDraftData.onSwitchItem}
            isEnabledSetting={isEnabledSetting}
            isDisabled={isDisabled}
            loading={loading}
          >
            {selectedPortfolio.name}
          </ConstraintsTitle>
        )}

        {isMultiPortfolio && (
          <StyledPageSectionActions>
            <Tooltip
              placement="topRight"
              title="This will propagate the arguments to the all portfolios"
            >
              <StyledTextButton
                $primary
                type="text"
                icon={<StyledCopyIcon />}
                onClick={() => setConfirmCopyOpen(true)}
                disabled={isConstraintsInit || isDisabled || loading}
              >
                Copy to all
              </StyledTextButton>
            </Tooltip>
            <StyledTextButton
              $primary
              type="text"
              icon={<StyledListIcon />}
              onClick={openSelectConstraintFromModal}
              data-cy="select-from"
              disabled={isConstraintsInit || isDisabled || loading}
            >
              Select from
            </StyledTextButton>
          </StyledPageSectionActions>
        )}
      </StyledPageSectionHeader>
      <>
        <StyledPageSection borderBottom>
          <StyledConstraintsCardTitle>
            Portfolio Objectives
          </StyledConstraintsCardTitle>
          <ObjectiveSelect
            defaultObjective={objective}
            constraintsData={constraintsDraftData}
            disabled={isDisabled}
          />
        </StyledPageSection>
        {loading && isEmpty(constraintsByCategory) && (
          <StyledPageSection>
            <Skeleton active />
          </StyledPageSection>
        )}
        {CONSTRAINTS_CATEGORIES_ORDER.map(
          key =>
            !isEmpty(constraintsByCategory[key]) && (
              <StyledPageSection borderBottom data-cy={key} key={key}>
                <StyledConstraintsCardTitle as="div">
                  {key}
                  <StyledCardActions>{getActions(key)}</StyledCardActions>
                </StyledConstraintsCardTitle>
                <ConstraintsList
                  simple={isSimple(key)}
                  canDelete={canDelete(key)}
                  constraintsData={constraintsDraftData}
                  constraints={getConstraints(key)}
                  loading={loading}
                  category={key}
                  attributeError={attributeError}
                  handleError={handleError}
                  setAttributeError={setAttributeError}
                  readOnly={isDisabled}
                />
                {canDelete(key) && !isEmpty(hiddenConstraints[key]) && (
                  <StyledWrapper>
                    <ConstraintsList
                      constraintsData={constraintsDraftData}
                      constraints={newConstraints}
                      category={key}
                      attributeError={attributeError}
                      handleError={handleError}
                      setAttributeError={setAttributeError}
                      readOnly={isDisabled}
                    />
                  </StyledWrapper>
                )}
              </StyledPageSection>
            ),
        )}
        <PortfolioListModal
          isOpen={selectFromOpen}
          onClose={closeSelectConstraintFromModal}
          constraintsData={constraintsDraftData}
          setIsLoading={setIsLoading}
          loading={loading}
        />
        <ConfirmCopyModal
          isOpen={confirmCopyOpen}
          onClose={() => setConfirmCopyOpen(false)}
          constraintsData={constraintsDraftData}
          setIsLoading={setIsLoading}
          loading={loading}
          isEnabledSetting={isEnabledSetting}
        />
      </>
    </StyledConstraintsBodyArea>
  );
};

ConstraintsContent.propTypes = {
  constraintsDraftData: PropTypes.shape(),
  isMultiPortfolio: PropTypes.bool,
  isDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  getWorkbookResults: PropTypes.func,
  setIsLoading: PropTypes.func,
};

export default ConstraintsContent;
