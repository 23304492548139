import PropTypes from "prop-types";
import { useCallback, useEffect, useMemo, useState, useRef } from "react";
import _, { maxBy, minBy, sortBy } from "lodash";

import {
  getTradeSummaryExpandedTransactionsDownload,
  getTradeSummaryCollapsedTransactionsDownload,
  getTradeSummaryTransactionsDownload,
  getTradeSummaryExpandedTransactions,
  getTradeSummaryInfo,
  getTradeSummaryCollapsedTransactions,
  getTradeCrossTransactions,
} from "../../../api/endpoints/results";
import { handleErrorMessages } from "../../../utils/messages";
import { COLUMN_FORMATTING } from "../../../constants/objectives";
import CheckboxRenderer from "../../../components/Form/AgGridEditableCellCheckbox";
import {
  DEFAULT_BUBBLE_CHART_OPTIONS,
  TAB_ITEMS,
} from "../../../features/ResultDetails/content/TradeSummary/constants";
import { gray4, greenLight, redLight } from "../../../constants/colors";
import { StyledTextButton } from "../../../components/Buttons/Button.styled";
import { StyledDetailsIcon } from "../../../components/Icons";
import { handleDownloadData } from "../../../utils/handleDownloadData";
import {
  getCrossTransactionData,
  getFilteredOFDropdownOptions,
  getPinnedBottomRowData,
  getCrossTransactionColumnDefs,
  prepareData,
  toNumber,
} from "./handlers";
import exportDataTableCSV from "../../../utils/exportDataTableCSV";

const defaultColDef = {
  filter: true,
  sortable: true,
  resizable: true,
};

const useTradeSummaryData = ({ workbookId, resultUUID }) => {
  const gridRef = useRef();
  const [isChartLoading, setIsChartLoading] = useState(false);
  const [isDetailsLoading, setIsDetailsLoading] = useState(false);

  const [tradeSummaryInfo, setTradeSummaryInfo] = useState(null);
  const [combinedData, setCombinedData] = useState(null);
  const [combinedColumnDefs, setCombinedColumnDefs] = useState(null);

  const [crossData, setCrossData] = useState(null);

  const [transactionsData, setTransactionsData] = useState(null);
  const [transactionsColumnDefs, setTransactionsColumnDefs] = useState(null);

  const [selectedObjectiveFunc, setSelectedObjectiveFunc] = useState();
  const [showChartParametrs, setShowChartParametrs] = useState(false);

  const [chartSeries, setChartSeries] = useState([]);
  const [visibleCharts, setVisibleCharts] = useState([]);
  const [chartOptions, setChartOptions] = useState(
    DEFAULT_BUBBLE_CHART_OPTIONS,
  );
  const [selectedDataPoint, setSelectedDataPoint] = useState(null);
  const [compareDataPoint, setCompareDataPoint] = useState(null);
  const [selectedValues, setSelectedValues] = useState(null);
  const [valuesToCompare, setValuesToCompare] = useState(null);
  const [selectedCombination, setSelectedCombination] = useState(null);
  const [selectedCollapseIndex, setSelectedCollapseIndex] = useState(0);
  const [tabViewSelected, setTabViewSelected] = useState(TAB_ITEMS[0].key);

  const filteredObjectiveDropdownOptions = useMemo(
    () =>
      tradeSummaryInfo ? getFilteredOFDropdownOptions(tradeSummaryInfo) : [],
    [tradeSummaryInfo],
  );

  const onBtnExportFiltered = useCallback(() => {
    exportDataTableCSV(
      gridRef,
      `export-trade-summary-${tabViewSelected}-view.csv`,
    );
  }, [tabViewSelected]);

  const onBtnExportAll = useCallback(
    (downloadCombined = false) => {
      const genIdsData = selectedCombination?.portfolioGenerations || {};
      const genIds = _.map(genIdsData, (data, key) => key);

      if (downloadCombined === true) {
        getTradeSummaryTransactionsDownload(genIds).then(response => {
          handleDownloadData(
            response,
            "export-trade-summary-combined-view.csv",
          );
        });
        return;
      }

      if (tabViewSelected === TAB_ITEMS[0].key) {
        getTradeSummaryCollapsedTransactionsDownload(genIds).then(response => {
          handleDownloadData(
            response,
            `export-trade-summary-${tabViewSelected}-view.csv`,
          );
        });
      } else if (tabViewSelected === TAB_ITEMS[2].key) {
        exportDataTableCSV(
          gridRef,
          `export-trade-summary-${tabViewSelected}-view.csv`,
          true,
        );
      } else {
        getTradeSummaryExpandedTransactionsDownload(genIds).then(response => {
          handleDownloadData(
            response,
            `export-trade-summary-${tabViewSelected}-view.csv`,
          );
        });
      }
    },
    [selectedCombination?.portfolioGenerations, tabViewSelected],
  );

  const handleGetDetails = loanxId => {
    setTabViewSelected(TAB_ITEMS[1].key);
    const filter = {
      "Loanx Id": {
        filter: loanxId,
        filterType: "text",
        type: "equals",
      },
    };
    gridRef.current.api.setFilterModel(filter);
    sessionStorage.setItem(
      "filterModeltradeSummaryCombined",
      JSON.stringify(filter),
    );
  };

  const getCombinedData = useCallback((genIds = []) => {
    setIsDetailsLoading(true);
    getTradeSummaryExpandedTransactions(genIds)
      .then(resp => {
        sessionStorage.removeItem("filterModeltradeSummaryCombined");
        sessionStorage.removeItem("filterModeltradeSummaryTransactions");
        const columns = _.map(resp.columns, "field");
        const resData = _.map(resp.data, row => _.zipObject(columns, row));
        setCombinedColumnDefs(resp.columns);
        setCombinedData(resData);
      })
      .catch(error => handleErrorMessages(error))
      .finally(() => setIsDetailsLoading(false));
  }, []);

  const getTransactionsData = useCallback((genIds = []) => {
    setIsDetailsLoading(true);
    getTradeSummaryCollapsedTransactions(genIds)
      .then(resp => {
        const columns = _.map(resp.columns, "field");
        const resData = _.map(resp.data, row => _.zipObject(columns, row));
        const defaultColumnDefs = resp.columns.map(column => {
          if (
            column.field === "Transaction Type" ||
            column.field === "Quantity"
          ) {
            return { ...column, sort: "asc" };
          }
          return column;
        });

        const newColumns = [
          ...defaultColumnDefs,
          {
            field: "",
            cellRenderer: value => {
              const loanxId = value.data["Loanx Id"];
              return (
                <StyledTextButton
                  $primary
                  type="text"
                  icon={<StyledDetailsIcon />}
                  onClick={() => handleGetDetails(loanxId)}
                >
                  Details
                </StyledTextButton>
              );
            },
          },
        ];
        setTransactionsColumnDefs(newColumns);
        setTransactionsData(resData);
      })
      .catch(error => handleErrorMessages(error))
      .finally(() => setIsDetailsLoading(false));
  }, []);

  const getTradeSummaryData = useCallback(() => {
    if (!resultUUID) return;
    setIsChartLoading(true);
    getTradeSummaryInfo(resultUUID)
      .then(data => setTradeSummaryInfo(data))
      .catch(error => handleErrorMessages(error))
      .finally(() => setIsChartLoading(false));
  }, [resultUUID]);

  useEffect(() => {
    getTradeSummaryData();
  }, [getTradeSummaryData]);

  const filterParams = useMemo(
    () => ({
      comparator: (inputDate, cellValue) => {
        const cellDate = new Date(Date.parse(cellValue));

        if (inputDate.getDate() === cellDate.getDate()) {
          return 0;
        }

        return inputDate < cellDate ? -1 : 1;
      },
      browserDatePicker: true,
      buttons: ["reset"],
    }),
    [],
  );

  const getProcessedColumnsDefs = useCallback(
    colDefs =>
      _.map(colDefs, val => {
        const flex = tabViewSelected === TAB_ITEMS[0].key ? 1 : null;
        const res = { ...val, checkboxSelection: false, minWidth: 150, flex };
        const formatter = COLUMN_FORMATTING[res.field];
        if (formatter) {
          res.valueFormatter = params => formatter.format(params.value);
        }
        if (res.filter === "agDateColumnFilter") {
          res.filterParams = filterParams;
        } else {
          res.filterParams = { buttons: ["reset"] };
        }
        if (res.field === "Include") {
          res.cellRenderer = cellData =>
            CheckboxRenderer({ ...cellData, disableInputs: true });
          res.cellClass = "grid-cell-centered";
          res.headerTooltip = "Include loan into the final portfolio result.";
        }
        return _.omit(res, ["objective_field", "objective_key"]);
      }),
    [filterParams, tabViewSelected],
  );

  const combinedProcessedColumnDefs =
    getProcessedColumnsDefs(combinedColumnDefs);

  const transactionsProcessedColumnDefs = getProcessedColumnsDefs(
    transactionsColumnDefs,
  );

  const combinedTableData = useMemo(
    () => ({
      data: combinedData,
      defaultColDef,
      gridRef,
      processedColumnDefs: combinedProcessedColumnDefs,
    }),
    [combinedData, combinedProcessedColumnDefs],
  );

  const getCrossTransactionsData = useCallback((genIds = []) => {
    setIsDetailsLoading(true);
    getTradeCrossTransactions(genIds)
      .then(response => {
        setCrossData(response);
      })
      .catch(error => handleErrorMessages(error))
      .finally(() => setIsDetailsLoading(false));
  }, []);

  const crossTransactionsData = useMemo(() => {
    if (!crossData) return null;
    const newData = getCrossTransactionData(crossData);
    const pinnedBottomRowData = getPinnedBottomRowData(newData);
    const processedColumnDefs = getCrossTransactionColumnDefs(crossData.values);

    return {
      data: sortBy(newData, item => item.Portfolio),
      defaultColDef,
      gridRef,
      processedColumnDefs,
      pinnedBottomRowData: [pinnedBottomRowData],
    };
  }, [crossData]);

  const getTableRowColor = useCallback(value => {
    if (value === "Buy") return greenLight;
    if (value === "Sell") return redLight;
    return gray4;
  }, []);

  const transactionsTableData = useMemo(
    () => ({
      data: transactionsData,
      defaultColDef,
      gridRef,
      processedColumnDefs: transactionsProcessedColumnDefs,
      getRowStyle: params => {
        const data = params.node.data || {};
        return {
          background: getTableRowColor(data["Transaction Type"]),
          borderBottom: "1px solid #e4e7ed",
        };
      },
    }),
    [getTableRowColor, transactionsData, transactionsProcessedColumnDefs],
  );

  const prepareChartOptions = useCallback(() => {
    const newSeries = [];

    if (tradeSummaryInfo) {
      // to prevent mutation
      const data = _.cloneDeep(tradeSummaryInfo?.solutions?.values);

      const isMinBest = tradeSummaryInfo?.isMinBest;

      filteredObjectiveDropdownOptions?.forEach(item => {
        // single chart data
        const chartItem = {
          name: item?.value,
          data: prepareData({
            source: data,
            selectedObjectiveFunc: item?.value,
            isMinBest,
          }),
        };

        // to support several chart series
        newSeries.push(chartItem);
      });

      setChartSeries(newSeries);
    }
  }, [tradeSummaryInfo, filteredObjectiveDropdownOptions]);

  const onClickChartOption = useCallback((event, chartContext, config) => {
    const dataIndex = config.selectedDataPoints?.[0]?.[0];
    setSelectedDataPoint(dataIndex !== undefined ? dataIndex : null);
  }, []);

  const handleChangeActivePortfolio = useCallback(
    value => {
      const portfolioIndex = value[0];
      const activePortfolio = selectedValues.sourceData[portfolioIndex];
      setSelectedCombination(activePortfolio || null);
      setSelectedCollapseIndex(portfolioIndex);
    },
    [selectedValues],
  );

  const generateDetailsLink = useCallback(
    genIdsData => {
      const resultDetailsUrl = `/workbooks/${workbookId}/results/${resultUUID}`;

      const genIds = _.map(genIdsData, (data, key) => key);
      return `${resultDetailsUrl}?generations=${genIds.join(",")}`;
    },
    [resultUUID, workbookId],
  );

  useEffect(() => {
    prepareChartOptions();
  }, [prepareChartOptions]);

  useEffect(() => {
    const visibleOptions = filteredObjectiveDropdownOptions?.map(
      item => item?.value,
    );
    setVisibleCharts(visibleOptions);
  }, [filteredObjectiveDropdownOptions]);

  useEffect(() => {
    if (filteredObjectiveDropdownOptions.length && !selectedObjectiveFunc) {
      setSelectedObjectiveFunc(filteredObjectiveDropdownOptions[0]?.value);
    }
  }, [filteredObjectiveDropdownOptions, selectedObjectiveFunc]);

  const handleSelectValues = (objFuncValue = null) => {
    const chart = chartSeries?.find(
      item => item?.name === selectedDataPoint?.objFunc,
    );
    const values = chart?.data[selectedDataPoint?.index] || [];
    const transactions = values?.x ?? 0;
    const objValue = objFuncValue || values?.y || 0;
    const sourceData = values?.itemSolutions || [];
    const minX = minBy(chart?.data, item => item?.x)?.x;
    const maxX = maxBy(chart?.data, item => item?.x)?.x;
    const lastIndex = maxX - minX;

    setSelectedValues({
      transactions,
      objValue,
      sourceData,
      lastIndex,
    });
    setSelectedCombination(sourceData[0]);
    setSelectedCollapseIndex(0);
  };

  const handleSelectOption = useCallback(
    point => {
      setSelectedDataPoint(point);
      handleSelectValues();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartSeries, selectedDataPoint?.index, selectedDataPoint?.objFunc],
  );

  useEffect(() => {
    if (selectedDataPoint !== null) {
      const chart = chartSeries?.find(
        item => item?.name === selectedDataPoint?.objFunc,
      );
      const values = chart?.data[selectedDataPoint?.index] || [];
      const sourceData = values?.itemSolutions || [];
      handleSelectValues(toNumber(sourceData[0]?.objective, "objective"));
    } else {
      setSelectedValues(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartSeries, selectedDataPoint]);

  useEffect(() => {
    setChartOptions(options => {
      const newOptions = { ...options };
      newOptions.chart = {
        ...options.chart,
        events: {
          dataPointSelection: onClickChartOption,
        },
      };
      return newOptions;
    });
  }, [onClickChartOption, setChartOptions]);

  useEffect(() => {
    if (selectedCombination) {
      const genIdsData = selectedCombination.portfolioGenerations || {};
      const genIds = _.map(genIdsData, (data, key) => key);
      getTransactionsData(genIds);
      getCombinedData(genIds);
      getCrossTransactionsData(genIds);
    }
  }, [
    getCombinedData,
    getCrossTransactionsData,
    getTransactionsData,
    selectedCombination,
  ]);

  return useMemo(
    () => ({
      isChartLoading,
      isDetailsLoading,
      combinedTableData,
      transactionsTableData,
      chartSeries,
      chartOptions,
      selectedValues,
      handleChangeActivePortfolio,
      selectedCollapseIndex,
      selectedCombination,
      generateDetailsLink,
      setTabViewSelected,
      tabViewSelected,
      onBtnExportAll,
      onBtnExportFiltered,
      setSelectedObjectiveFunc,
      selectedObjectiveFunc,
      filteredObjectiveDropdownOptions,
      setSelectedDataPoint,
      selectedDataPoint,
      setSelectedValues,
      prepareChartOptions,
      handleSelectOption,
      setShowChartParametrs,
      showChartParametrs,
      setVisibleCharts,
      visibleCharts,
      setValuesToCompare,
      valuesToCompare,
      setCompareDataPoint,
      compareDataPoint,
      isMinBest: tradeSummaryInfo?.isMinBest,
      crossTransactionsData,
    }),
    [
      isChartLoading,
      isDetailsLoading,
      combinedTableData,
      transactionsTableData,
      chartSeries,
      chartOptions,
      selectedValues,
      handleChangeActivePortfolio,
      selectedCollapseIndex,
      selectedCombination,
      generateDetailsLink,
      setTabViewSelected,
      tabViewSelected,
      onBtnExportAll,
      onBtnExportFiltered,
      setSelectedObjectiveFunc,
      selectedObjectiveFunc,
      filteredObjectiveDropdownOptions,
      setSelectedDataPoint,
      selectedDataPoint,
      setSelectedValues,
      prepareChartOptions,
      handleSelectOption,
      setShowChartParametrs,
      showChartParametrs,
      setVisibleCharts,
      visibleCharts,
      setValuesToCompare,
      valuesToCompare,
      setCompareDataPoint,
      compareDataPoint,
      tradeSummaryInfo,
      crossTransactionsData,
    ],
  );
};

useTradeSummaryData.propTypes = {
  resultUUID: PropTypes.string,
};

export default useTradeSummaryData;
