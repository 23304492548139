import PropTypes from "prop-types";
import { useCallback } from "react";
import { Skeleton } from "antd";
import Chart from "../Chart/chart";
import { OBJECTIVE_CARD_CONFIG } from "../../features/ResultDetails/constants";
import {
  StyledDoubleArrowUpIcon,
  StyledDoubleArrowDownIcon,
  StyledArrowCircleLeftIcon,
  StyledEmojiEventIcon,
} from "../Icons";
import {
  StyledStatisticLabel,
  StyledStatisticText,
  StyledStatisticWrapper,
  StyledDiffItem,
  StyledDiffItemsWrapper,
  StyledDiffItemWrapper,
  StyledDiffLabel,
  StyledChartWrapper,
} from "./styles";
import { StyledPageContent } from "../../features/styled";
import { parseObjectiveName } from "../../utils/parseObjectiveName";
import { transformFloatingNumbers } from "../../utils/dataFormatters";

const handleGetDiffValue = (value, valueToCompare, order) => {
  const diffValue =
    order === "max" ? value - valueToCompare : valueToCompare - value;
  return Number(diffValue.toFixed(2));
};

const ObjectiveStatisticCard = ({
  showPrevStepDiff,
  showLastStepDiff,
  step,
  resultList,
  overviewChartData,
  objective,
}) => {
  const currentObjOrder = objective?.[0]?.order;
  const labelYFormatter = useCallback(value => value, []);

  const { title, dataIndex } = OBJECTIVE_CARD_CONFIG;
  const currentStep = resultList?.find(result => result.step === step);
  const prevStep = resultList?.find(result => result.step === step - 1);

  const lastStep = resultList?.find(
    result => result.step === resultList.length - 1,
  );

  const showDiff = resultList.some(
    item => item?.[dataIndex] !== currentStep?.[dataIndex],
  );

  const value = currentStep?.[dataIndex];

  const prevStepValue = showPrevStepDiff && prevStep?.[dataIndex];

  const lastStepValue = showLastStepDiff && lastStep?.[dataIndex];

  const prevValueDiff = showPrevStepDiff
    ? handleGetDiffValue(value, prevStepValue, currentObjOrder)
    : false;

  const lastStepDiff =
    showLastStepDiff &&
    handleGetDiffValue(value, lastStepValue, currentObjOrder);

  if (!overviewChartData) {
    return (
      <StyledPageContent $inner>
        <Skeleton active />
      </StyledPageContent>
    );
  }
  const currentObjFuncName = parseObjectiveName(objective);
  return (
    <StyledStatisticWrapper first>
      <div>
        <StyledStatisticLabel>{title}</StyledStatisticLabel>
        <StyledStatisticText>
          {transformFloatingNumbers(currentStep?.[dataIndex])}
        </StyledStatisticText>
      </div>
      <StyledChartWrapper>
        <Chart
          chartHeight={200}
          data={overviewChartData}
          xField="step"
          yField={currentObjFuncName}
          labelFormatter={text => `Step ${text}`}
          labelYFormatter={labelYFormatter}
          hideAxis
          chartId="objective-chart"
          showTooltip={false}
          step={step}
          lastStepIcon={`${process.env.PUBLIC_URL}/assets/images/icons/emoji_events.svg`}
        />
      </StyledChartWrapper>
      <StyledDiffItemsWrapper>
        {showDiff && showPrevStepDiff && (
          <StyledDiffItemWrapper>
            <StyledDiffLabel>per prev. step</StyledDiffLabel>
            <StyledDiffItem>
              <StyledArrowCircleLeftIcon />
              {Boolean(prevValueDiff) &&
                (prevValueDiff < 0 ? (
                  <StyledDoubleArrowDownIcon />
                ) : (
                  <StyledDoubleArrowUpIcon />
                ))}
              <span>{prevValueDiff}</span>
            </StyledDiffItem>
          </StyledDiffItemWrapper>
        )}
        {showDiff && showLastStepDiff && (
          <StyledDiffItemWrapper>
            <StyledDiffLabel>per best step</StyledDiffLabel>
            <StyledDiffItem>
              <StyledEmojiEventIcon />
              {Boolean(lastStepDiff) &&
                (lastStepDiff < 0 ? (
                  <StyledDoubleArrowDownIcon />
                ) : (
                  <StyledDoubleArrowUpIcon />
                ))}
              <span>{lastStepDiff}</span>
            </StyledDiffItem>
          </StyledDiffItemWrapper>
        )}
      </StyledDiffItemsWrapper>
    </StyledStatisticWrapper>
  );
};

ObjectiveStatisticCard.propTypes = {
  showPrevStepDiff: PropTypes.bool,
  showLastStepDiff: PropTypes.bool,
  step: PropTypes.number.isRequired,
  resultList: PropTypes.arrayOf(PropTypes.shape()),
  overviewChartData: PropTypes.arrayOf(PropTypes.shape()),
  objective: PropTypes.arrayOf(PropTypes.shape()),
};

export default ObjectiveStatisticCard;
