import styled from "styled-components/macro";
import { Modal } from "antd";
import {
  gray5,
  darkGrey4,
  darkGrey3,
  white,
  lightGreyShadow,
} from "../../constants/colors";

export const StyledModal = styled(Modal)`
  max-width: ${({ $fullScreen }) => ($fullScreen ? 1500 : 1000)};

  .ant-modal-content {
    box-shadow: 0 4px 12px ${gray5};
    position: relative;
    padding: ${({ noContentPadding }) => (noContentPadding ? 0 : "24px")};
  }

  .ant-modal-header {
    border-bottom: none;
    padding: ${({ noHeaderPadding }) =>
      noHeaderPadding ? "0 0 24px" : "24px 24px 16px 24px"};
    box-shadow: ${({ scrollTop }) =>
      scrollTop > 0 ? `0px 2px 10px -2px ${lightGreyShadow}` : "none"};
  }

  .ant-modal-title {
    font-weight: 500;
    font-size: 28px;
    line-height: 40px;
    color: ${darkGrey4};
  }

  .ant-modal-close {
    top: 18px;
    right: 18px;
    &:hover {
      background-color: transparent;
    }
  }

  .ant-modal-body {
    padding-top: 0px;
  }

  .ant-modal-wrap {
    background-color: ${darkGrey4};
  }
`;

export const StyledModalPrimaryText = styled.span`
  font-size: 14px;
  line-height: 22px;
  color: ${darkGrey3};
`;

export const StyledModalBoldText = styled.span`
  font-weight: 700;
  font-size: 14px;
  line-height: 22px;
  color: ${darkGrey3};
`;

export const StyledModalButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 24px;
  background-color: ${white};
  position: sticky;
  bottom: 0px;
  padding-top: 24px;
`;

export const StyledModalShadowBtnContainer = styled(
  StyledModalButtonsContainer,
)`
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  padding: 24px;
  box-sizing: border-box;
  box-shadow: 0 -2px 10px ${lightGreyShadow};
`;

export const StyledModalScrollContainer = styled.div`
  max-height: calc(100vh - 350px);
  min-height: 200px;
  min-width: 500px;
  overflow-y: auto;
  padding: 24px 24px 100px 24px;
`;

export const StyledModalSubtitle = styled.div`
  font-size: 16px;
  line-height: 22px;
  color: ${darkGrey4};
  margin-bottom: 24px;
  display: flex;
  gap: 8px;
`;
