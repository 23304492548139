import styled, { css } from "styled-components/macro";
import { Layout, Button } from "antd";

import {
  blue,
  darkBlue,
  darkGrey1,
  darkGrey6,
  gray1,
  gray3,
  gray6,
  white,
  gold,
  gray4,
} from "../constants/colors";

const { Content } = Layout;

export const StyledLayout = styled(Layout)`
  display: flex;
  flex-flow: column nowrap;
  background-color: ${gray3};
`;

export const StyledLayoutContent = styled(Content)`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column nowrap;
  min-height: 100vh;
`;

export const StyledHeader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-height: 64px;
  padding: 0 32px 0;

  background-color: ${darkGrey6};
  color: ${gray1};
`;

export const StyledHeaderLogo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledSubHeader = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 1.5rem 2rem 0;
  border-bottom: 1px solid ${gray4};
  background-color: ${white};
  color: ${darkGrey1};
  overflow-x: auto;
`;

export const StyledHeaderContent = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1 auto;
  min-height: 4.75rem;
  align-items: stretch;

  ${StyledSubHeader} & {
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const StyledHeaderMenu = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 5rem;

  @media only screen and (max-width: 576px) {
    flex-direction: column;
    padding: 10px 0 0;
    align-items: flex-start;
    gap: unset;
  }
`;

export const StyledMenuList = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 0 auto;
`;

export const StyledActionsList = styled.div`
  display: flex;
  flex-flow: row wrap;
  flex: 1 1 auto;
  justify-content: flex-end;

  > button {
    margin: 0 0.25rem;
  }
`;

export const StyledMenuActions = styled.div`
  display: flex;
  margin-left: auto;
`;

export const StyledMenuItem = styled.div`
  cursor: pointer;
  color: currentColor;
  background-color: transparent;
  border-bottom: 2px solid transparent;
  text-transform: uppercase;

  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  align-items: center;
  justify-content: center;
  margin-right: 32px;
  padding: 1rem 0;
  position: relative;

  transition: all 0.3s ease;

  ${props =>
    props.active &&
    css`
      color: ${blue};
      border-color: ${blue};
    `}

  &:hover {
    color: ${blue};
  }
`;

export const StyledActionItem = styled.div`
  cursor: pointer;
  color: currentColor;
  background-color: transparent;

  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  align-items: center;
  padding: 1rem;
  position: relative;

  transition: all 0.3s ease;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0.2;
    background-color: transparent;
    transition: all 0.3s ease;
  }

  &:hover {
    &:before {
      background-color: ${darkBlue};
    }
  }
`;

export const StyledMenuButton = styled.div`
  cursor: pointer;
  color: #ffffffa6;
  background-color: transparent;

  display: none;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: 64px;
  width: 64px;
  align-self: center;

  transition: all 0.3s ease;

  &:hover {
    background-color: #0a6ebd;
    color: #ffffff;
  }

  .anticon,
  svg {
    pointer-events: none;
  }

  @media only screen and (max-width: 768px) {
    display: flex;
  }
`;

export const StyledMenuMobileList = styled.div`
  display: none;
  flex-flow: column nowrap;
  background-color: ${darkGrey6};
  color: ${gray1};

  position: absolute;
  top: 64px;
  left: 0;
  z-index: 999;
  min-width: 300px;
  width: 100%;

  ${StyledMenuItem} {
    padding: 1rem;
  }

  @media only screen and (max-width: 768px) {
    ${props =>
      props.open &&
      css`
        display: flex;
      `}
  }
`;

export const StyledButton = styled(Button)`
  text-transform: uppercase;
  letter-spacing: 0.4px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;

  &.ant-btn-primary {
    color: ${white};
    background: ${blue};
    border-color: ${blue};
    &:disabled {
      background: ${gray6};
      border-color: ${gray6};
      &:hover {
        color: ${white};
      }
    }
  }
`;

export const StyledMainHeaderMenu = styled.div`
  display: flex;
  flex-flow: row wrap;
  height: 100%;
`;

export const StyledMainHeaderActions = styled.div`
  display: flex;
  flex-flow: row wrap;
  margin-left: auto;
`;

export const StyledMenuItemIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  color: ${gray6};
  transition: color 0.3s ease;

  svg path {
    fill: currentColor;
    fill-opacity: 1;
  }
`;

export const StyledMenuItemTitle = styled.div`
  color: ${gray6};
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;

  letter-spacing: 0.4px;
  text-transform: uppercase;
`;

export const StyledMainHeaderMenuItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  cursor: pointer;
  position: relative;

  &:hover {
    ${StyledMenuItemIcon} {
      color: ${gold};
    }
  }

  ${props =>
    props.active &&
    css`
      ${StyledMenuItemIcon} {
        color: ${gold};
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        width: 4rem;
        height: 0.25rem;
        background-color: ${gold};
      }
    `}
`;

export const StyledTimeElapsedWrapper = styled.span`
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
`;
