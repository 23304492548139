import PropTypes from "prop-types";
import {
  PROGRESS_STATES,
  PROGRESS_STATES_LABEL,
} from "../../constants/constraints";
import {
  StyledStateLabel,
  StyledStatusLabelRow,
} from "../../features/Shared/styled";
import { StyledWarningIcon } from "../Icons";
import { gold } from "../../constants/colors";

const StateLabel = ({ status }) => {
  const label =
    status === PROGRESS_STATES.finishedPartially
      ? PROGRESS_STATES_LABEL.FINISHED
      : PROGRESS_STATES_LABEL[status];

  return (
    <StyledStatusLabelRow>
      <StyledStateLabel status={status}>
        {label}
        {status === PROGRESS_STATES.finishedPartially && (
          <StyledWarningIcon color={gold} />
        )}
      </StyledStateLabel>
    </StyledStatusLabelRow>
  );
};

StateLabel.displayName = "StateLabel";

StateLabel.propTypes = {
  status: PropTypes.string.isRequired,
};

export default StateLabel;
