import styled from "styled-components/macro";
import { maxScreensSizesPx } from "../styled";

export const StyledTableWrapper = styled.div`
  padding: 1rem 2rem;

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    padding: 1rem;
  }
`;

export const StyledActionsRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-end;
  gap: 1rem;
`;

export const StyledAnimatedSyncOutlineIconWrapper = styled.span`
  width: 86px;
  display: flex;
  align-items: center;
  justify-content: "flex-end";
`;

export default StyledTableWrapper;
