export const CARDS_CONFIG = [
  {
    title: "Portfolio WARF",
    dataIndex: "warf",
    key: "warf",
    precision: 0,
  },
  {
    title: "Number of Holdings",
    dataIndex: "number_of_holdings",
    key: "number_of_holdings",
    precision: 0,
  },
  {
    title: "Overall Notional",
    dataIndex: "overall_notional",
    key: "overall_notional",
    precision: 0,
    prefix: "$",
  },
  {
    title: "Portfolio Maturity",
    dataIndex: "loan_maturity",
    key: "loan_maturity",
    precision: 2,
    suffix: " Years",
  },
  {
    title: "Senior Secure Percentage",
    dataIndex: "senior_secured",
    key: "senior_secured",
    precision: 2,
    suffix: "%",
  },
  {
    title: "Number of Transactions",
    dataIndex: "number_of_transactions",
    key: "number_of_transactions",
    precision: 0,
  },
  {
    title: "Weighted Average Spread",
    dataIndex: "was",
    key: "was",
    precision: 2,
  },
];

export const OBJECTIVE_CARD_CONFIG = {
  title: "Objective function value",
  dataIndex: "objective",
  key: "objective",
  precision: 2,
};
