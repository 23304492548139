import PropTypes from "prop-types";
import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router";
import { Tooltip } from "antd";
import { StyledWorkbookActions } from "../styles";
import {
  StyledEditOutlineIcon,
  StyledPeopleOutlineIcon,
  StyledShareIcon,
  StyledTrashOutlineIcon,
} from "../../../components/Icons";
import { StyledTextButton } from "../../../components/Buttons/Button.styled";
import EditWorkbookModal from "./EditWorkbookModal";
import ShareWorkbookModal from "./ShareWorkbookModal";
import DeleteWorkbookModal from "./DeleteModal";
import { darkGrey5 } from "../../../constants/colors";
import * as routes from "../../../constants/routes";
import { PageContext } from "../../../layout/AppLayout/context";
import { StyledModalBoldText } from "../../../components/Modals/ModalDialog.styled";

const MODALS = {
  share: "SHARE",
  edit: "EDIT",
};

const WorkbookActions = ({
  workbook = {},
  onShareWorkbook,
  onDeleteWorkbook,
  onEditWorkbook,
}) => {
  const { uuid: id, name } = workbook;
  const isOwner = workbook?.role === "owner";
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState("");
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const { user } = useContext(PageContext);

  const closeModal = useCallback(() => {
    setModalOpen("");
  }, []);

  const openEditModal = useCallback(event => {
    setModalOpen(MODALS.edit);
    event.stopPropagation();
  }, []);

  const openShareModal = useCallback(event => {
    setModalOpen(MODALS.share);
    event.stopPropagation();
  }, []);

  const handleDeleteWorkbookClick = useCallback(
    event => {
      onDeleteWorkbook(id);
      event.stopPropagation();
    },
    [id, onDeleteWorkbook],
  );

  return (
    <>
      <StyledWorkbookActions data-cy={`row-actions-${id}`}>
        <Tooltip
          placement="topLeft"
          title="Rename Workbook"
          arrow
          color={darkGrey5}
          overlayInnerStyle={{ fontFamily: "Onest" }}
          overlayStyle={{}}
        >
          <StyledTextButton
            type="text"
            icon={<StyledEditOutlineIcon />}
            onClick={openEditModal}
            data-cy="rename-workbook"
            $iconOnly
            disabled={!isOwner}
          />
        </Tooltip>
        <Tooltip
          placement="topLeft"
          title="Share Workbook"
          arrow
          color={darkGrey5}
          overlayInnerStyle={{ fontFamily: "Onest" }}
        >
          <StyledTextButton
            type="text"
            data-cy="share-workbook"
            icon={<StyledShareIcon />}
            onClick={openShareModal}
            $iconOnly
            disabled={!isOwner}
          />
        </Tooltip>
        <Tooltip
          placement="topLeft"
          title={
            !isOwner ? "This workbook has been shared with you" : "Contributors"
          }
          arrow
          color={darkGrey5}
          overlayInnerStyle={{ fontFamily: "Onest" }}
        >
          <StyledTextButton
            type="text"
            icon={<StyledPeopleOutlineIcon />}
            disabled={!isOwner}
            onClick={event => {
              event.stopPropagation();
              navigate(`${routes.WORKBOOKS}/${id}/${routes.CONTRIBUTORS}`);
            }}
            $iconOnly
          />
        </Tooltip>
        {user?.isSuperuser && (
          <Tooltip
            placement="topLeft"
            title="Delete workbook"
            arrow
            color={darkGrey5}
            overlayInnerStyle={{ fontFamily: "Onest" }}
          >
            <StyledTextButton
              $iconOnly
              data-cy="remove-workbook-button"
              type="text"
              icon={<StyledTrashOutlineIcon />}
              onClick={event => {
                event.stopPropagation();
                setDeleteModalOpen(true);
              }}
            />
          </Tooltip>
        )}
        <DeleteWorkbookModal
          title="Remove Workbook"
          subtitle={
            <>
              You are trying to delete the
              <StyledModalBoldText> &quot;{name}&quot;</StyledModalBoldText>.
              Are you sure you want to remove this Workbook?
            </>
          }
          isOpen={deleteModalOpen}
          name={name}
          onClose={() => setDeleteModalOpen(false)}
          onDelete={handleDeleteWorkbookClick}
        />
      </StyledWorkbookActions>
      <EditWorkbookModal
        isOpen={modalOpen === MODALS.edit}
        onClose={closeModal}
        onEditWorkbook={onEditWorkbook}
        workbook={workbook}
      />
      <ShareWorkbookModal
        isOpen={modalOpen === MODALS.share}
        onClose={closeModal}
        onShareWorkbook={onShareWorkbook}
        workbook={workbook}
      />
    </>
  );
};

WorkbookActions.propTypes = {
  workbook: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  onShareWorkbook: PropTypes.func,
  onDeleteWorkbook: PropTypes.func,
  onEditWorkbook: PropTypes.func,
};

export default WorkbookActions;
