import { useCallback, useMemo, useState, useEffect } from "react";
import WorkbookName from "../content/WorkbookName";
import WorkbookActions from "../content/WorkbookActions";
import {
  addWorkbook,
  deleteWorkbook,
  editWorkbook,
  getWorkbooksList,
  shareWorkbook,
} from "../../../api/endpoints/workbooks";
import {
  handleErrorMessages,
  showMessageSuccess,
} from "../../../utils/messages";
import useQuery from "../../../hooks/useQuery";
import handleFormatDate from "../../../utils/handleFormatDate";

const useWorkbooksData = () => {
  const { query, setQuery } = useQuery();

  const [page, setPage] = useState(+query.get("page") || 1);
  const [total, setTotal] = useState(0);
  const [workbooks, setWorkbooks] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [paginationData, setPaginationData] = useState({
    size: "small",
    position: "bottomRight",
    showSizeChanger: false,
    current: page || 1,
    defaultCurrent: 1,
    total: total || workbooks.length || 0,
    pages: 0,
  });

  useEffect(() => {
    const currentPage = query.get("page");
    setPage(+currentPage || 1);
  }, [query]);

  useEffect(() => {
    setPaginationData(data => ({ ...data, current: page, total }));
  }, [page, total]);

  const getWorkbooks = ({ page: newPage, showLoadingState = false }) => {
    setIsLoading(showLoadingState);
    getWorkbooksList(newPage)
      .then(({ items = [], total: totalItems, pages }) => {
        setWorkbooks(items.map(wbk => ({ ...wbk, key: wbk.name })));
        setTotal(totalItems);
        setPaginationData(data => ({ ...data, pages }));
      })
      .catch(error => handleErrorMessages(error))
      .finally(() => setIsLoading(false));
  };

  useEffect(() => {
    getWorkbooks({ page, showLoadingState: true });
  }, [page]);

  const onAddWorkbook = useCallback((name, callback, setLoading = () => {}) => {
    setLoading(true);
    addWorkbook({ name })
      .then(() => {
        getWorkbooks({ showLoadingState: false });
        if (callback && typeof callback === "function") {
          callback();
        }
        setLoading(false);
      })
      .catch(error => {
        handleErrorMessages(error);
        setLoading(false);
      });
  }, []);

  const onEditWorkbook = useCallback(
    (id, name, callback, setLoading = () => {}) => {
      setLoading(true);
      editWorkbook(id, { name })
        .then(() => {
          getWorkbooks({ showLoadingState: false });
          if (callback && typeof callback === "function") {
            callback();
          }
          setLoading(false);
          showMessageSuccess({ text: "Workbook was updated!" });
        })
        .catch(error => {
          handleErrorMessages(error);
          setLoading(false);
        });
    },
    [],
  );

  const onPageChange = useCallback(
    currentPage => {
      setQuery("page", currentPage);
      setPage(currentPage);
    },
    [setQuery],
  );

  const onDeleteWorkbook = useCallback(
    (id, callback) => {
      deleteWorkbook(id)
        .then(() => {
          getWorkbooks({ showLoadingState: false });
          onPageChange(1);
          if (callback && typeof callback === "function") {
            callback();
          }
        })
        .catch(error => handleErrorMessages(error));
    },
    [onPageChange],
  );

  const onShareWorkbook = useCallback(
    (id, email, callback, setLoading = () => {}) => {
      setLoading(true);
      shareWorkbook(id, { user_email: email })
        .then(contributor => {
          getWorkbooks({ showLoadingState: false });
          if (callback && typeof callback === "function") {
            callback(contributor);
          }
          setLoading(false);
          showMessageSuccess({ text: "Workbook was shared!" });
        })
        .catch(error => {
          handleErrorMessages(error);
          setLoading(false);
        });
    },
    [],
  );

  const handleToggleFeatured = useCallback((id, isFeatured) => {
    setWorkbooks(data =>
      data.map(workbook =>
        workbook.uuid === id ? { ...workbook, isFeatured } : workbook,
      ),
    );
  }, []);

  const columns = useMemo(
    () => [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        render: (data, record) => <WorkbookName workbook={record} />,
      },
      {
        title: "Created at",
        dataIndex: "createdAt",
        key: "createdAt",
        render: data => handleFormatDate({ value: data, showTime: true }),
      },
      {
        title: "Updated at",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: data => handleFormatDate({ value: data, showTime: true }),
      },
      {
        title: "Actions",
        dataIndex: "actions",
        key: "actions",
        align: "right",
        render: (data, record) => (
          <div
            onClick={event => event.stopPropagation()}
            onKeyDown={event => event.stopPropagation()}
            tabIndex="0"
            role="button"
            aria-pressed="false"
          >
            <WorkbookActions
              workbook={record}
              onShareWorkbook={onShareWorkbook}
              onDeleteWorkbook={onDeleteWorkbook}
              onEditWorkbook={onEditWorkbook}
            />
          </div>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [handleToggleFeatured, onShareWorkbook, onDeleteWorkbook, onEditWorkbook],
  );

  return {
    isLoading,
    workbooks,
    columns,
    onAddWorkbook,
    paginationData,
    onPageChange,
    onEditWorkbook,
    onShareWorkbook,
    onDeleteWorkbook,
  };
};

export default useWorkbooksData;
