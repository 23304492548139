import styled, { css } from "styled-components/macro";
import {
  blueWhite,
  gray4,
  lightRed,
  accentRed,
  greenWhite,
  green,
  gray50,
  gray80,
  gray70,
  whiteYellow,
  gold,
  blue,
  lightViolet,
  accentViolet,
  gray8,
} from "../../constants/colors";
import { PROGRESS_STATES } from "../../constants/constraints";

export const STATUSES = {
  error: "error",
  success: "success",
  disabled: "disabled",
};

export const StyledLabelNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  color: inherit;
  background-color: ${({ isActive }) =>
    isActive ? `${blueWhite}` : `${gray4}`};
  border-radius: 500rem;
  height: 24px;
  width: 24px;
`;

export const StyledMenuList = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 1 1 auto;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledLoadingTabs = styled.div`
  display: flex;
  gap: 32px;
  height: 50px;
  align-items: flex-end;
  .ant-skeleton.ant-skeleton-element .ant-skeleton-button {
    width: 120px;
  }
`;

export const StyledStatusLabel = styled.span`
  display: inline-block;
  padding: 2px 8px;
  text-transform: uppercase;

  ${props =>
    props.status === STATUSES.error &&
    css`
      background-color: ${lightRed};
      color: ${accentRed};
    `}

  ${props =>
    props.status === STATUSES.success &&
    css`
      background-color: ${greenWhite};
      color: ${green};
    `}

    ${props =>
    props.status === STATUSES.disabled &&
    css`
      background-color: ${gray4};
      color: ${gray8};
    `}
`;

export const StyledStateLabel = styled.span`
  display: flex;
  flex-flow: row;
  padding: 0 8px;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  column-gap: 8px;
  max-height: 24px;
  min-height: 24px;
  width: 110px;
  box-sizing: border-box;

  ${props =>
    (props.status === PROGRESS_STATES.draft ||
      props.status === PROGRESS_STATES.finishedPartially) &&
    css`
      background-color: ${whiteYellow};
      color: ${gold};
    `}

  ${props =>
    props.status === PROGRESS_STATES.cancelled &&
    css`
      background-color: ${lightRed};
      color: ${accentRed};
    `}

    ${props =>
    props.status === PROGRESS_STATES.failed &&
    css`
      background-color: ${lightRed};
      color: ${accentRed};
    `}

    ${props =>
    props.status === PROGRESS_STATES.inProgress &&
    css`
      background-color: ${blueWhite};
      color: ${blue};
    `}

    ${props =>
    props.status === PROGRESS_STATES.queued &&
    css`
      background-color: ${lightViolet};
      color: ${accentViolet};
    `}

    ${props =>
    props.status === PROGRESS_STATES.finished &&
    css`
      background-color: ${greenWhite};
      color: ${green};
    `}
`;

export const StyledStatusLabelRow = styled.span`
  display: flex;
  flex-flow: row;
  justify-content: flex-start;
`;

export const StyledBreadcrumbsLabel = styled.div`
  color: ${gray50};
  font-size: 14px;
  line-height: 16px;
  cursor: not-allowed;

  ${props =>
    props.$bold &&
    css`
      font-weight: 700;
      color: ${gray80};
    `}

  ${props =>
    props.$dark &&
    css`
      color: ${gray70};
      cursor: pointer;
    `}
`;

export const StyledBreadcrumbsBtn = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  line-height: 16px;
  height: fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
`;

export const StyledBreadcrumbsContainer = styled.div`
  display: flex;
  align-items: self-end;
  gap: 4px;
`;

export const StyledGenerateBtnContainer = styled.div`
  justify-self: flex-end;
  align-self: flex-end;
  margin-top: auto;
  margin-left: auto;
  width: fit-content;
  padding-bottom: 1rem;
`;
