import {
  isNull,
  filter,
  head,
  minBy,
  maxBy,
  orderBy,
  keys as lodashKeys,
  isUndefined,
} from "lodash";
import { crossTransactionFields, objectivesOptions } from "./constants";
import { PROGRESS_STATES } from "../../../constants/constraints";
import { blue, red90 } from "../../../constants/colors";
import { moneyIntFormatter } from "../../../utils/dataFormatters";

export const toNumber = (num, objectiveFunc) => {
  if (Number(num) > 10 || objectiveFunc === "weightedWarf") {
    return +Number(num).toFixed(0);
  }
  return +Number(num).toFixed(4);
};

export const getNewItemWithFilteredSolutions = ({
  item,
  isMinimised,
  filterKey,
}) => {
  const { x: xaxisValue, y: yaxisValue, itemSolutions } = item;

  const filteredByObject = isMinimised
    ? minBy(itemSolutions, solution => solution?.[filterKey])
    : maxBy(itemSolutions, solution => solution?.[filterKey]);

  const newItemSolutions = itemSolutions.filter(
    solution => solution?.[filterKey] === filteredByObject[filterKey],
  );
  const newItemData = {
    x: xaxisValue,
    y: yaxisValue,
    itemSolutions: newItemSolutions,
  };

  return newItemData;
};

export const prepareData = ({ source, selectedObjectiveFunc, isMinBest }) => {
  let result = [];
  const isObjectiveSelected = selectedObjectiveFunc === "objective";
  const isMinimised = isMinBest[selectedObjectiveFunc];

  source.forEach(item => {
    const {
      transactions,
      averageCurrentHoldings,
      averageSellAmount,
      portfolioGenerations,
      weightedYearsTm,
      weightedWarf,
      weightedBidAskSpread,
      weightedSpreadBps,
      weightedSpread,
      objective,
    } = item;

    const selectedValue = item[selectedObjectiveFunc];

    const bubbleXaxisValue = transactions;
    const bubbleYaxisValue = toNumber(selectedValue, selectedObjectiveFunc);
    const bubbleSolutions = [
      {
        portfolioGenerations,
        averageCurrentHoldings,
        averageSellAmount,
        weightedYearsTm,
        weightedWarf,
        weightedBidAskSpread,
        weightedSpreadBps,
        weightedSpread,
        objective,
      },
    ];

    const existResultIndex = result.findIndex(resultItem => {
      const { x, y } = resultItem;
      return x === bubbleXaxisValue && y === bubbleYaxisValue;
    });

    const chartDataItem = {
      x: bubbleXaxisValue,
      y: bubbleYaxisValue,
      itemSolutions: bubbleSolutions,
    };

    if (existResultIndex === -1) {
      // add new bubble
      result.push(chartDataItem);
    } else {
      // add new solution to existing bubble data
      const existingResultItem = result[existResultIndex];
      const { itemSolutions } = existingResultItem;
      itemSolutions?.push(bubbleSolutions[0]);
    }
  });

  if (isObjectiveSelected) {
    const newResult = result.reduce((acc, item) => {
      const { x: xaxisValue, y: yaxisValue } = item;
      const newItemData = getNewItemWithFilteredSolutions({
        item,
        isMinimised,
        filterKey: selectedObjectiveFunc,
      });

      if (acc?.[xaxisValue]) {
        let newItem;
        const { y: savedYaxisValue } = acc[xaxisValue];

        if (isMinimised) {
          newItem =
            savedYaxisValue > yaxisValue ? newItemData : acc[xaxisValue];
        } else {
          newItem =
            savedYaxisValue < yaxisValue ? newItemData : acc[xaxisValue];
        }
        return { ...acc, [xaxisValue]: newItem };
      }

      return { ...acc, [xaxisValue]: newItemData };
    }, {});

    result = Object.values(newResult);
  }
  const filteredResult = orderBy(result, ["x"], ["asc"]);

  return filteredResult;
};

export const getFilteredOFDropdownOptions = tradeSummaryInfo => {
  const data = tradeSummaryInfo?.solutions?.values;
  let keysToRemove = [];

  if (data?.length) {
    const dataWithNullValues = filter(data, item => {
      const hasNullValue = Object.values(item).filter(value => isNull(value));
      return hasNullValue ? item : false;
    });

    const firstItem = head(dataWithNullValues);
    let keys = firstItem ? Object.entries(firstItem) : [];

    keys = keys.map(([key, value]) => {
      if (isNull(value)) {
        return key;
      }
      return false;
    });

    keysToRemove = filter(keys, key => key);
  }
  const filteredObjectivesOptions = filter(
    objectivesOptions,
    option => !keysToRemove.includes(option.value),
  );
  return filteredObjectivesOptions;
};

export const removeSkippedPortfolios = portfolios => {
  return Object.entries(portfolios).reduce((acc, [key, value]) => {
    if (value.status === PROGRESS_STATES.skipped) {
      return acc;
    }
    // eslint-disable-next-line no-param-reassign
    acc[key] = value;
    return acc;
  }, {});
};

export const getPinnedBottomRowData = data => {
  const result = data.reduce((acc, item) => {
    Object.entries(item).forEach(([key, value]) => {
      if (key.includes(crossTransactionFields.BuySell)) {
        // eslint-disable-next-line no-param-reassign
        acc[key] = value + (acc[key] ?? 0);
      }
    });

    return acc;
  }, {});

  result[crossTransactionFields.Portfolio] = "sum";

  return result;
};

export const getCrossTransactionPortfolioColDefs = () => {
  return {
    headerName: crossTransactionFields.Portfolio,
    children: [
      {
        pinned: "left",
        headerName: "",
        field: crossTransactionFields.Portfolio,
        cellStyle: params => {
          const isPinnedRow = params.node.rowPinned === "bottom";

          return {
            fontWeight: isPinnedRow ? 600 : "inherit",
            fontSize: isPinnedRow ? 16 : "normal",
          };
        },
      },
    ],
  };
};

export const getCrossTransactionColumnDefs = crossData => {
  const loanIdsHeaders = lodashKeys(crossData);
  const valueFormatter = params => {
    if (isUndefined(params.value)) {
      return null;
    }
    return moneyIntFormatter.format(params.value);
  };

  const defs = loanIdsHeaders.map(loanxId => {
    return {
      headerName: loanxId,
      children: [
        {
          headerName: "held",
          minWidth: 100,
          maxWidth: 110,
          field: `${crossTransactionFields.CurrentHoldings} ${loanxId}`,
          valueFormatter,
        },
        {
          headerName: "buy/sell",
          minWidth: 100,
          maxWidth: 110,
          field: `${crossTransactionFields.BuySell} ${loanxId}`,
          valueFormatter,
          cellStyle: params => {
            const fontWeight =
              params.node.rowPinned === "bottom" ? 700 : "inherit";

            if (params.value < 0) {
              return { color: red90, fontWeight };
            }
            return { color: blue, fontWeight };
          },
        },
      ],
    };
  });

  const portfolioColDefs = getCrossTransactionPortfolioColDefs();

  return [portfolioColDefs, ...defs];
};

export const getCrossTransactionData = ({ mapping, values }) => {
  const data =
    Object.entries(values)?.reduce((acc, [loanxId, loanArr]) => {
      loanArr.forEach(loan => {
        loan.forEach(([mapIndex, buySell, currentHoldings]) => {
          const portfolio = mapping[mapIndex];

          // eslint-disable-next-line no-param-reassign
          acc[portfolio] = {
            ...acc[portfolio],
            [crossTransactionFields.LoanxId]: loanxId,
            [`${crossTransactionFields.Portfolio} ${loanxId}`]: portfolio,
            [`${crossTransactionFields.CurrentHoldings} ${loanxId}`]:
              currentHoldings,
            [`${crossTransactionFields.BuySell} ${loanxId}`]: buySell,
          };
        });
      });

      return acc;
    }, {}) ?? {};

  const newData = Object.entries(data).map(([key, value]) => ({
    [crossTransactionFields.Portfolio]: key,
    ...value,
  }));

  return newData;
};
