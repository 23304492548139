export const newDefaultConstraint = { new: {} };

export const NEW_CONSTRAINT_KEY = "new";

export const PROGRESS_STATES = {
  finished: "FINISHED",
  finishedPartially: "FINISHED_PARTIALLY",
  queued: "QUEUED",
  inProgress: "IN_PROGRESS",
  cancelled: "CANCELLED",
  failed: "FAILED",
  draft: "DRAFT",
  skipped: "SKIPPED",
};

export const PROGRESS_STATES_LABEL = {
  FINISHED: "finished",
  FINISHED_PARTIALLY: "finished partially",
  QUEUED: "queued",
  IN_PROGRESS: "in progress",
  CANCELLED: "cancelled",
  FAILED: "failed",
  DRAFT: "draft",
  SKIPPED: "skipped",
};

export const ALL_PROGRESS_STATES_WITH_LABEL = Object.keys(PROGRESS_STATES).map(
  key => ({
    label: PROGRESS_STATES_LABEL[PROGRESS_STATES[key]],
    value: PROGRESS_STATES[key],
  }),
);

export const PERCENT_TEXT_TYPE = "percent/text";

export const DEBOUNCE_TIME = 1000;
