import { useCallback, useEffect, useState, useMemo } from "react";
import { Tooltip } from "antd";
import PropTypes from "prop-types";
import { LoadingOutlined } from "@ant-design/icons";
import _ from "lodash";
import NumberInput from "../../../components/Form/NumberInput";
import {
  StyledActionsRow,
  StyledConstraintItemActions,
  StyledConstraintItemInner,
  StyledConstraintItemWrapper,
  StyledConstraintTextArea,
  StyledConstraintTextAreaContent,
  StyledLoadingWrapper,
  StyledSkeletonInput,
} from "../styles";
import NumberInputRange from "../../../components/Form/NumberInputRange";
import { Select } from "../../../components/Form/Select/Select";
import {
  CONSTRAINTS_INPUT_TYPE_VALUES,
  CONSTRAINTS_INPUT_TYPES,
} from "../../../constants/objectives";
import { StyledCloseIcon, StyledInfoIcon } from "../../../components/Icons";
import { StyledTextButton } from "../../../components/Buttons/Button.styled";
import {
  DEBOUNCE_TIME,
  NEW_CONSTRAINT_KEY,
  PERCENT_TEXT_TYPE,
} from "../../../constants/constraints";
import { ConstraintPropType, InputPropType } from "./types";
import EditConstraintQueryModal from "./EditConstraintQueryModal";
import EditConstraintButton from "./EditConstraintButton";

const ConstraintsItem = ({
  isSimple = false,
  canDelete = false,
  readOnly = false,
  dataLoading = false,
  constraint = {},
  loading,
  valueFieldName,
  inputType: initInputType,
  constraintsData,
  attributeError = [],
  handleError = () => {},
  setAttributeError = () => {},
  category,
}) => {
  const {
    isEnabled = false,
    min = null,
    max = null,
    eq: eqVal = null,
    help = "",
    title = "",
    type,
    text,
  } = constraint;

  const [status, setStatus] = useState(min <= max ? "default" : "error");
  const currentErrors = attributeError?.filter(
    error => error?.valueFieldName === valueFieldName,
  );

  const numberInputType = useMemo(() => {
    return type === PERCENT_TEXT_TYPE ? "percent" : type;
  }, [type]);

  useEffect(() => {
    if (currentErrors?.length) {
      setStatus("error");
    }
  }, [currentErrors]);

  const {
    hiddenConstraints,
    onSwitchItem,
    onChangeConstraint,
    onUpdateConstraint,
    onUpdateConstraintData,
    onChangeConstraintInputType,
  } = constraintsData;
  const [selectOptions, setSelectOptions] = useState([]);

  useEffect(() => {
    const selOptions = [
      {
        label: title,
        value: valueFieldName === NEW_CONSTRAINT_KEY ? "" : valueFieldName,
      },
    ];

    _.forEach(hiddenConstraints[category], (data, key) => {
      selOptions.push({ label: data.title, value: key });
    });

    setSelectOptions(selOptions);
  }, [category, hiddenConstraints, title, valueFieldName]);

  const [inputType, setInputType] = useState(initInputType);
  const [minValue, setMinValue] = useState(min);
  const [maxValue, setMaxValue] = useState(max);
  const [eqValue, setEqValue] = useState(eqVal);
  const [showQueryModal, setShowQueryModal] = useState(false);

  useEffect(() => {
    setMinValue(min);
  }, [min]);

  useEffect(() => {
    setMaxValue(max);
  }, [max]);

  useEffect(() => {
    setEqValue(eqVal);
  }, [eqVal]);

  const handleChangeInputType = useCallback(
    value => {
      setInputType(value);
      onChangeConstraintInputType(valueFieldName, value);
    },
    [onChangeConstraintInputType, valueFieldName],
  );

  const handleSuccess = () => {
    setAttributeError([]);
  };

  useEffect(() => {
    const errorIndex = attributeError.findIndex(
      item => item.valueFieldName === valueFieldName,
    );

    if (
      minValue > maxValue &&
      inputType === CONSTRAINTS_INPUT_TYPE_VALUES.RANGE
    ) {
      if (errorIndex === -1) {
        setAttributeError(prevAttributeError => [
          ...prevAttributeError,
          { attributeNameError: "min", valueFieldName },
        ]);
      }
    } else if (errorIndex > -1) {
      setAttributeError(prevAttributeError => {
        prevAttributeError.splice(errorIndex, 1);
        return prevAttributeError;
      });
    }
  }, [
    attributeError,
    inputType,
    maxValue,
    minValue,
    setAttributeError,
    valueFieldName,
  ]);

  const handleOnChange = useCallback(
    (name, attributeName, value) => {
      if (attributeName === "max") setMaxValue(value);
      if (attributeName === "min") setMinValue(value);

      if (inputType === CONSTRAINTS_INPUT_TYPE_VALUES.RANGE) {
        setStatus("default");
        onUpdateConstraintData({
          name,
          options: {
            min: attributeName === "min" ? value : minValue,
            max: attributeName === "max" ? value : maxValue,
          },
          category,
          handleError,
        });
      } else {
        onUpdateConstraint({
          name,
          attributeName,
          value,
          category,
          handleError,
          handleSuccess,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      inputType,
      minValue,
      maxValue,
      onUpdateConstraintData,
      category,
      onUpdateConstraint,
    ],
  );

  const debounce = useMemo(
    () =>
      _.debounce(
        (name, attributeName, value) =>
          handleOnChange(name, attributeName, value),
        DEBOUNCE_TIME,
      ),
    [handleOnChange],
  );

  const handleInputChange = useCallback(
    (name, attributeName, value) => {
      debounce(name, attributeName, value);
    },
    [debounce],
  );

  function handleOnPercentText(name, attributeName, value) {
    onUpdateConstraint({
      name,
      attributeName,
      value,
      category,
      handleError,
      handleSuccess,
    });
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceOnPercentText = useCallback(
    _.debounce(handleOnPercentText, DEBOUNCE_TIME),
    [],
  );

  const handlePercentTextChange = useCallback(
    (name, attributeName, value) => {
      debounceOnPercentText(name, attributeName, value);
    },
    [debounceOnPercentText],
  );

  const handleDisableConstraint = useCallback(() => {
    onSwitchItem({
      key: valueFieldName,
      switchArg: false,
      min: minValue,
      max: maxValue,
    });
  }, [valueFieldName, onSwitchItem, maxValue, minValue]);

  const checkIsError = useCallback(
    fieldName => {
      const hasError = currentErrors?.find(
        item => item?.attributeNameError === fieldName,
      );
      return Boolean(hasError);
    },
    [currentErrors],
  );

  const inputTypeValue = CONSTRAINTS_INPUT_TYPES.find(
    item => item.value === inputType,
  );

  return (
    <StyledConstraintItemWrapper data-cy={valueFieldName}>
      {isSimple && (
        <>
          <StyledConstraintTextArea readOnly={readOnly}>
            <StyledConstraintTextAreaContent>
              {title}
            </StyledConstraintTextAreaContent>
          </StyledConstraintTextArea>
          <StyledConstraintTextArea readOnly={readOnly} small>
            <StyledConstraintTextAreaContent>
              {inputTypeValue?.label}
            </StyledConstraintTextAreaContent>
          </StyledConstraintTextArea>
        </>
      )}
      {!isSimple && (
        <>
          <Select
            menuPortalTarget={document.body}
            name="constaint-type"
            onChange={value =>
              onChangeConstraint(valueFieldName, value, category)
            }
            value={valueFieldName}
            options={selectOptions}
            placeholder="Select constraint"
            isOptionDisabled={option => !option?.value}
            disabled={readOnly}
          />

          <Select
            menuPortalTarget={document.body}
            onChange={handleChangeInputType}
            value={inputType}
            name="constaint-condition"
            disabled={!isEnabled || readOnly}
            placeholder="Select Operator"
            options={CONSTRAINTS_INPUT_TYPES}
          />
        </>
      )}

      {dataLoading && <StyledSkeletonInput active />}

      <StyledConstraintItemInner>
        {type === PERCENT_TEXT_TYPE && (
          <EditConstraintButton
            onClick={() => setShowQueryModal(true)}
            text={text?.join(", ")}
            disabled={readOnly}
          />
        )}

        {inputType === CONSTRAINTS_INPUT_TYPE_VALUES.RANGE && !dataLoading && (
          <NumberInputRange
            type={numberInputType}
            width="100%"
            disabled={!isEnabled || readOnly}
            onChange={handleInputChange}
            name={valueFieldName}
            fromInputAttrs={{
              defaultValue: minValue || 0,
              min: 0,
            }}
            toInputAttrs={{
              defaultValue: maxValue || 0,
              min: 0,
            }}
            min={0}
            title={title}
            status={status}
            checkIsError={checkIsError}
          />
        )}

        {inputType === CONSTRAINTS_INPUT_TYPE_VALUES.GREAT_OR_EQUAL &&
          !dataLoading && (
            <NumberInput
              type={numberInputType}
              title={title}
              width="100%"
              disabled={!isEnabled || readOnly}
              defaultValue={minValue || 0}
              name={valueFieldName}
              data-cy="constraint-value"
              attributeName="min"
              status={status}
              min={0}
              showError={checkIsError("min")}
              onChange={handleInputChange}
            />
          )}

        {inputType === CONSTRAINTS_INPUT_TYPE_VALUES.LESS_OR_EQUAL &&
          !dataLoading && (
            <NumberInput
              type={numberInputType}
              title={title}
              width="100%"
              disabled={!isEnabled || readOnly}
              defaultValue={maxValue || 0}
              name={valueFieldName}
              data-cy="constraint-value"
              attributeName="max"
              min={0}
              status={status}
              showError={checkIsError("max")}
              onChange={handleInputChange}
            />
          )}

        {inputType === CONSTRAINTS_INPUT_TYPE_VALUES.EQUAL && !dataLoading && (
          <NumberInput
            type={numberInputType}
            title={title}
            width="100%"
            disabled={!isEnabled || readOnly}
            defaultValue={eqValue || 0}
            name={valueFieldName}
            data-cy="constraint-value"
            attributeName="eq"
            min={0}
            status={status}
            showError={checkIsError("eq")}
            onChange={handleInputChange}
          />
        )}

        {!inputType && (
          <StyledConstraintTextArea readOnly>value</StyledConstraintTextArea>
        )}

        <StyledConstraintItemActions>
          {canDelete && (
            <StyledActionsRow>
              {loading ? (
                <StyledLoadingWrapper>
                  <LoadingOutlined />
                </StyledLoadingWrapper>
              ) : (
                <StyledTextButton
                  $transparent
                  $noPadding
                  $autoHeight
                  shape="circle"
                  data-cy="remove-constraints"
                  icon={<StyledCloseIcon />}
                  onClick={handleDisableConstraint}
                  disabled={readOnly}
                />
              )}
            </StyledActionsRow>
          )}

          {help && (
            <Tooltip title={help}>
              <StyledInfoIcon $hasMargin={!canDelete} />
            </Tooltip>
          )}
        </StyledConstraintItemActions>
      </StyledConstraintItemInner>

      {type === PERCENT_TEXT_TYPE && showQueryModal && (
        <EditConstraintQueryModal
          isOpen={showQueryModal}
          name={valueFieldName}
          attributeName="text"
          onClose={() => setShowQueryModal(false)}
          onChange={handlePercentTextChange}
          defaultValue={text}
        />
      )}
    </StyledConstraintItemWrapper>
  );
};

ConstraintsItem.propTypes = {
  isSimple: PropTypes.bool,
  canDelete: PropTypes.bool,
  dataLoading: PropTypes.bool,
  readOnly: PropTypes.bool,
  attributeError: PropTypes.arrayOf(PropTypes.shape()),
  setAttributeError: PropTypes.func,
  handleError: PropTypes.func,
  constraint: ConstraintPropType,
  loading: PropTypes.bool,
  category: PropTypes.string,
  valueFieldName: PropTypes.string.isRequired,
  inputType: InputPropType,
  constraintsData: PropTypes.shape(),
};

export default ConstraintsItem;
