import PropTypes from "prop-types";
import * as routes from "../../../constants/routes";
import { StyledNavLink, StyledWorkbookName } from "../styles";

const WorkbookName = ({ workbook = {} }) => {
  const { uuid: id, name } = workbook;

  return (
    <StyledWorkbookName data-cy={`workbook-name-${name}`}>
      <StyledNavLink>{name}</StyledNavLink>
    </StyledWorkbookName>
  );
};

WorkbookName.propTypes = {
  workbook: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
};

export default WorkbookName;
