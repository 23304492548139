import PropTypes from "prop-types";
import { StyledTextButton } from "../../../components/Buttons/Button.styled";
import { StyledEditOutlineIcon } from "../../../components/Icons";
import {
  StyledEditConstraintButtonQuery,
  StyledEditConstraintButtonWrapper,
} from "../styles";

const EditConstraintButton = ({ disabled, text, onClick }) => {
  return (
    <StyledEditConstraintButtonWrapper>
      {text && (
        <StyledEditConstraintButtonQuery title={text}>
          {text}
        </StyledEditConstraintButtonQuery>
      )}
      <StyledTextButton
        disabled={disabled}
        onClick={onClick}
        $primary
        $iconOnly
        $noPadding
        $autoHeight
        type="text"
        icon={<StyledEditOutlineIcon />}
      />
    </StyledEditConstraintButtonWrapper>
  );
};

EditConstraintButton.propTypes = {
  disabled: PropTypes.bool,
  text: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default EditConstraintButton;
