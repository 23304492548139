import { useContext, useEffect, useState } from "react";
import { Tooltip, Upload, message } from "antd";
import { useOktaAuth } from "@okta/okta-react";
import { LoadingOutlined } from "@ant-design/icons";

import { PageContext } from "../../layout/AppLayout/context";
import {
  StyledUserBlock,
  StyledTitle,
  StyledRow,
  StyledRowTitle,
  StyledRowValue,
  StyledEditBtnContainer,
  StyledRowValueEditDisabled,
  StyledRowValueNotEdit,
  StyledRowValueEditInputContainer,
  StyledPasswordValidationContainer,
  StyledPasswordValidationRow,
  StyledAvatarContainer,
  StyledAvatarImg,
} from "./styled";
import StyledButton from "../../components/Buttons/Button.styled";
import { StyledInput } from "../../components/Form/styles";
import {
  StyledCheckIcon,
  StyledDashIcon,
  StyledDefaultAvatarIcon,
  StyledErrorCloseIcon,
} from "../../components/Icons";
import {
  changePassword,
  deleteProfilePicture,
  updateProfile,
} from "../../api/endpoints/users";
import { handleErrorMessages, showMessageSuccess } from "../../utils/messages";
import { white } from "../../constants/colors";
import DeleteModal from "../Workbooks/content/DeleteModal";

const UserInfo = () => {
  const { user, handleGetUser } = useContext(PageContext);
  const [fileLoading, setFileLoading] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const { authState, oktaAuth } = useOktaAuth();
  const { isAuthenticated } = authState || {};
  const isOktaAuthFlow = process.env.REACT_APP_AUTH_FLOW === "okta";
  const accessToken = localStorage.getItem("accessToken");

  const oktaToken = isAuthenticated ? oktaAuth.getAccessToken() : null;
  const token = isOktaAuthFlow
    ? process.env.REACT_APP_FAKE_TOKEN || oktaToken
    : process.env.REACT_APP_FAKE_TOKEN || accessToken;

  const [
    { oldPassword, newPassword, repeatNewPassword, firstName, lastName },
    setFormValues,
  ] = useState({
    oldPassword: "",
    newPassword: "",
    repeatNewPassword: "",
    firstName: "",
    lastName: "",
  });

  const doesPasswordIncludeNumber = /(?=.*\d)/.test(newPassword);
  const doesPasswordIncludeSpecialSymbols = /(?=.*[^\w\s])/.test(newPassword);
  const doesPasswordIncludesUppercaseLetter = /(?=.*[A-Z])/.test(newPassword);
  const isPasswordValid =
    newPassword?.length >= 8 &&
    doesPasswordIncludeNumber &&
    doesPasswordIncludeSpecialSymbols &&
    doesPasswordIncludesUppercaseLetter;
  const doPasswordsMatch = newPassword === repeatNewPassword;

  const isButtonPasswordDisabled =
    !(oldPassword && newPassword && repeatNewPassword) ||
    !isPasswordValid ||
    !doPasswordsMatch;

  const isButtonUserNameDisabled =
    !firstName ||
    !lastName ||
    (firstName &&
      lastName &&
      firstName === user?.firstName &&
      lastName === user?.lastName);

  const isButtonDisabled = isButtonPasswordDisabled && isButtonUserNameDisabled;

  const passwordMatchError =
    !doPasswordsMatch && repeatNewPassword && newPassword;

  const handleChange = event => {
    setSubmitError(null);
    const { name, value } = event.target;
    setFormValues(prevState => ({ ...prevState, [name]: value }));
  };

  const props = {
    name: "file",
    action: "/api/v1/users/profile-picture/",
    headers: { Authorization: `Bearer ${token}` },
    onChange(info) {
      if (info.file.status === "uploading") {
        setFileLoading(true);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
        setFileLoading(false);
        handleGetUser();
      } else if (info.file.status === "error") {
        setFileLoading(false);
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleDeleteProfilePicture = async () => {
    try {
      await deleteProfilePicture();
      showMessageSuccess({ text: "Profile picture has been deleted!" });
      setDeleteOpen(false);
      handleGetUser();
    } catch (error) {
      handleErrorMessages(error);
    }
  };

  const handleSaveSettings = async () => {
    setSubmitError(null);
    let errorData;

    try {
      if (oldPassword && newPassword && repeatNewPassword) {
        await changePassword({ oldPassword, newPassword, repeatNewPassword });
        showMessageSuccess({ text: "Password was changed!" });
      }
    } catch (error) {
      const messageText = error?.response?.data?.detail;
      setSubmitError({ status: error?.response?.status, text: messageText });
      errorData = error;
      handleErrorMessages(error);
    }

    try {
      if (
        firstName &&
        lastName &&
        (firstName !== user?.firstName || lastName !== user?.lastName)
      ) {
        await updateProfile({ firstName, lastName });
        showMessageSuccess({ text: "User name has been updated!" });
        handleGetUser();
      }
    } catch (error) {
      const messageText = error?.response?.data?.detail;
      setSubmitError({ status: error?.response?.status, text: messageText });
      errorData = error;
      handleErrorMessages(error);
    }

    if (!errorData) {
      setFormValues({
        oldPassword: "",
        newPassword: "",
        repeatNewPassword: "",
      });
      setIsEdit(false);
    }
  };

  const handleCancel = () => {
    setSubmitError(null);
    setFormValues({
      oldPassword: "",
      newPassword: "",
      repeatNewPassword: "",
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
    });
    setIsEdit(false);
  };

  useEffect(() => {
    if (isEdit) {
      setFormValues(prevState => ({
        ...prevState,
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
      }));
    }
  }, [isEdit, user?.firstName, user?.lastName]);

  return (
    <StyledUserBlock>
      <StyledEditBtnContainer>
        {isEdit ? (
          <>
            <StyledButton
              type="secondary"
              $autoWidth
              $lowercase
              $height={34}
              $padding="6px 12px"
              onClick={handleCancel}
            >
              Cancel
            </StyledButton>
            <StyledButton
              $autoWidth
              $lowercase
              $height={34}
              $padding="6px 12px"
              disabled={isButtonDisabled}
              onClick={handleSaveSettings}
            >
              Save
            </StyledButton>
          </>
        ) : (
          <StyledButton
            type="secondary"
            $lowercase
            $autoWidth
            $height={34}
            $padding="6px 12px"
            onClick={() => setIsEdit(true)}
          >
            Edit Account
          </StyledButton>
        )}
      </StyledEditBtnContainer>
      <StyledTitle>Account Settings</StyledTitle>
      <StyledRow>
        {user?.profilePicture ? (
          <StyledAvatarContainer>
            <StyledAvatarImg
              src={`data:image/jpeg;base64,${user?.profilePicture}`}
              alt=""
              width={70}
            />
          </StyledAvatarContainer>
        ) : (
          <StyledAvatarContainer>
            <StyledDefaultAvatarIcon $width="70px" />
          </StyledAvatarContainer>
        )}
        {isEdit && (
          <StyledRowValueEditInputContainer>
            <Tooltip title="Click on button or drag file to upload profile photo">
              <Upload
                {...props}
                multiple={false}
                showProgress
                showUploadList={false}
              >
                <StyledButton
                  $autoWidth
                  $lowercase
                  $height={34}
                  $width={180}
                  $padding="6px 12px"
                  disabled={fileLoading}
                >
                  {fileLoading ? (
                    <LoadingOutlined style={{ fontSize: 20, color: white }} />
                  ) : (
                    "Upload profile photo"
                  )}
                </StyledButton>
              </Upload>
            </Tooltip>
            {user?.profilePicture && (
              <StyledButton
                type="secondary"
                $lowercase
                $autoWidth
                $height={34}
                $padding="6px 12px"
                onClick={() => setDeleteOpen(true)}
                disabled={fileLoading}
              >
                Delete
              </StyledButton>
            )}
          </StyledRowValueEditInputContainer>
        )}
      </StyledRow>
      <StyledRow>
        <StyledRowTitle>E-mail</StyledRowTitle>
        {isEdit ? (
          <StyledRowValueEditDisabled>{user?.email}</StyledRowValueEditDisabled>
        ) : (
          <StyledRowValueNotEdit>
            <StyledRowValue>{user?.email}</StyledRowValue>
          </StyledRowValueNotEdit>
        )}
      </StyledRow>
      {isEdit ? (
        <>
          <StyledRow>
            <StyledRowTitle>First name</StyledRowTitle>
            <StyledRowValueEditInputContainer>
              <StyledInput
                name="firstName"
                value={firstName}
                onChange={handleChange}
              />
            </StyledRowValueEditInputContainer>
          </StyledRow>
          <StyledRow>
            <StyledRowTitle>Last name</StyledRowTitle>
            <StyledRowValueEditInputContainer>
              <StyledInput
                name="lastName"
                value={lastName}
                onChange={handleChange}
              />
            </StyledRowValueEditInputContainer>
          </StyledRow>
        </>
      ) : (
        <StyledRow>
          <StyledRowTitle>Name</StyledRowTitle>
          <StyledRowValueNotEdit>
            <StyledRowValue>
              {user?.firstName} {user?.lastName}
            </StyledRowValue>
          </StyledRowValueNotEdit>
        </StyledRow>
      )}
      <StyledRow>
        <StyledRowTitle>Password</StyledRowTitle>
        {isEdit ? (
          <Tooltip title={submitError?.status === 401 ? submitError?.text : ""}>
            <StyledRowValueEditInputContainer
              $error={submitError?.status === 401}
            >
              <StyledInput.Password
                name="oldPassword"
                value={oldPassword}
                onChange={handleChange}
              />
            </StyledRowValueEditInputContainer>
          </Tooltip>
        ) : (
          <StyledRowValueNotEdit>
            <StyledRowValue>••••••••••••</StyledRowValue>
          </StyledRowValueNotEdit>
        )}
      </StyledRow>
      {isEdit && (
        <>
          <StyledRow>
            <StyledRowTitle>New Password</StyledRowTitle>
            <Tooltip title={passwordMatchError ? "Passwords do not match" : ""}>
              <StyledRowValueEditInputContainer $error={passwordMatchError}>
                <StyledInput.Password
                  name="newPassword"
                  value={newPassword}
                  onChange={handleChange}
                />
              </StyledRowValueEditInputContainer>
            </Tooltip>
          </StyledRow>
          <StyledRow>
            <StyledRowTitle>Repeat Password</StyledRowTitle>
            <Tooltip title={passwordMatchError ? "Passwords do not match" : ""}>
              <StyledRowValueEditInputContainer $error={passwordMatchError}>
                <StyledInput.Password
                  name="repeatNewPassword"
                  value={repeatNewPassword}
                  onChange={handleChange}
                />
              </StyledRowValueEditInputContainer>
            </Tooltip>
          </StyledRow>
          <StyledRow>
            <div />
            <StyledRowValueEditInputContainer>
              <StyledPasswordValidationContainer>
                <StyledPasswordValidationRow>
                  {!newPassword && <StyledDashIcon />}
                  {newPassword && newPassword?.length < 8 && (
                    <StyledErrorCloseIcon />
                  )}
                  {newPassword && newPassword?.length >= 8 && (
                    <StyledCheckIcon />
                  )}
                  <span>At least 8 characters</span>
                </StyledPasswordValidationRow>
                <StyledPasswordValidationRow>
                  {!newPassword && <StyledDashIcon />}
                  {newPassword && doesPasswordIncludesUppercaseLetter && (
                    <StyledCheckIcon />
                  )}
                  {newPassword && !doesPasswordIncludesUppercaseLetter && (
                    <StyledErrorCloseIcon />
                  )}
                  <span>At least 1 upper case character</span>
                </StyledPasswordValidationRow>
                <StyledPasswordValidationRow>
                  {!newPassword && <StyledDashIcon />}
                  {newPassword && doesPasswordIncludeNumber && (
                    <StyledCheckIcon />
                  )}
                  {newPassword && !doesPasswordIncludeNumber && (
                    <StyledErrorCloseIcon />
                  )}
                  <span>At least 1 number</span>
                </StyledPasswordValidationRow>
                <StyledPasswordValidationRow>
                  {!newPassword && <StyledDashIcon />}
                  {newPassword && doesPasswordIncludeSpecialSymbols && (
                    <StyledCheckIcon />
                  )}
                  {newPassword && !doesPasswordIncludeSpecialSymbols && (
                    <StyledErrorCloseIcon />
                  )}
                  <span>At least 1 special character (@#$^&*+=)</span>
                </StyledPasswordValidationRow>
              </StyledPasswordValidationContainer>
            </StyledRowValueEditInputContainer>
          </StyledRow>
        </>
      )}
      <DeleteModal
        isOpen={deleteOpen}
        onClose={() => setDeleteOpen(false)}
        onDelete={handleDeleteProfilePicture}
        title="Remove Profile Picture"
        subtitle="You are trying to delete your profile picture. Are you sure you want to remove it?"
      />
    </StyledUserBlock>
  );
};

export default UserInfo;
