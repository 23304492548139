import PropTypes from "prop-types";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { StyledTextButton } from "../../../components/Buttons/Button.styled";
import { StyledResetWrapper } from "../styles";

const infoText =
  "Data is filtered, to show all records hit reset filters button.";

const ResetFilter = ({
  clearFilters,
  showUndoBtn = false,
  noInnerPadding = false,
  noMargin = false,
  showText = true,
}) => {
  const navigate = useNavigate();

  const handleUndo = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <StyledResetWrapper noInnerPadding={noInnerPadding} noMargin={noMargin}>
      {showUndoBtn && (
        <StyledTextButton onClick={handleUndo}>Undo</StyledTextButton>
      )}
      <Tooltip title={infoText}>
        <StyledTextButton onClick={clearFilters}>
          Reset Filters
        </StyledTextButton>
      </Tooltip>
      {showText && <div>{infoText}</div>}
    </StyledResetWrapper>
  );
};

ResetFilter.propTypes = {
  clearFilters: PropTypes.func.isRequired,
  noInnerPadding: PropTypes.bool,
  showUndoBtn: PropTypes.bool,
  showText: PropTypes.bool,
  noMargin: PropTypes.bool,
};

export default ResetFilter;
