import { useEffect, useState, useCallback } from "react";
import { useOutletContext } from "react-router";
import PropTypes from "prop-types";
import { Empty, Skeleton, Typography } from "antd";
import { isEmpty } from "lodash";
import { CARDS_CONFIG } from "../../../constants";
import {
  StyledSectionColumn,
  StyledSectionRow,
  StyledStatisticContainer,
  StyledTitleActions,
  StyledTitleArea,
} from "../styles";
import {
  StyledPageContent,
  StyledPageSection,
  StyledPageTitle,
} from "../../../../styled";
import ObjectiveStatisticCard from "../../../../../components/ObjectiveStatisticCard";
import StyledCard from "../../../../../components/Card";
import ToggleInput from "../../../../../components/Form/Toggle";
import DataTable from "../../../../DataFilters/DataTable";
import { StyledMenuActions } from "../../../../../layout/styles";
import {
  StyledBookIcon,
  StyledExcelIcon,
} from "../../../../../components/Icons";
import StyledButton, {
  StyledTextButton,
  StyledTextPrimaryButton,
} from "../../../../../components/Buttons/Button.styled";
import StepStatisticCard from "./StepStatisticCard";
import useDetailsData from "./useDetailsData";
import DistributionChartCard from "./DistributionChartCard";
import BarChartCard from "./BarChartCard";
import RatingChartCard from "./RatingChartCard";
import ListCard from "./ListCard";
import { downloadPortfolioStepResults } from "../../../../../api/endpoints/portfolios";
import { handleDownloadData } from "../../../../../utils/handleDownloadData";
import ConstraintsValuesModal from "../../modals/ConstraintsValuesModal";
import exportDataTableCSV from "../../../../../utils/exportDataTableCSV";

const { Text } = Typography;

const StepDetails = ({
  step,
  selectedGenerationUUID,
  getPortfolioResultAtStep,
  constraintsData,
  portfolioResultData,
}) => {
  const workbookLayoutContext = useOutletContext();

  const { allConstraintsValue } = workbookLayoutContext;
  const { resultList, getOverviewChartData } = portfolioResultData;

  const {
    objective,
    currentObjName,
    getObjectiveValue,
    getFormattedValue,
    selectedPortfolio,
    isSellReplaceEnabled,
  } = constraintsData;

  const [overviewChartData, setOverviewChartData] = useState(null);
  const [showCharts, setShowCharts] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isConstrainValuesModalOpen, setConstrainValuesModalOpen] =
    useState(false);
  const [isFilteredLoans, setIsFilteredLoans] = useState(false);

  const allConstraintsValueArr = allConstraintsValue
    ? Object.values(allConstraintsValue)
    : [];

  const showConstraintValuesModal = useCallback(() => {
    setConstrainValuesModalOpen(true);
  }, [setConstrainValuesModalOpen]);

  useEffect(() => {
    setOverviewChartData(getOverviewChartData(false));
  }, [getOverviewChartData]);

  const { filterProps, chartsData } = useDetailsData({
    selectedPortfolioId: selectedPortfolio?.uuid,
    step,
    disableInputs: true,
    getPortfolioResultAtStep,
  });

  const { isLoading, onFileUploaded, topLoanAgents, gridRef } = filterProps;

  useEffect(() => {
    if (!isLoaded) {
      onFileUploaded({ refreshData: false });
      setIsLoaded(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step]);

  const onBtnExportAll = useCallback(() => {
    downloadPortfolioStepResults(selectedGenerationUUID).then(response => {
      handleDownloadData(response, `export-step-${step}.csv`);
    });
  }, [selectedGenerationUUID, step]);

  const onBtnExportFiltered = useCallback(() => {
    exportDataTableCSV(gridRef, `export-step-${step}.csv`);
  }, [gridRef, step]);

  const handleToggleCharts = () => {
    setShowCharts(isOpen => !isOpen);
  };

  const TableFooter = (
    <StyledMenuActions>
      {isFilteredLoans && (
        <StyledTextPrimaryButton
          type="text"
          icon={<StyledExcelIcon />}
          onClick={onBtnExportFiltered}
        >
          Export filtered
        </StyledTextPrimaryButton>
      )}
      <StyledTextPrimaryButton
        type="text"
        icon={<StyledExcelIcon />}
        onClick={onBtnExportAll}
      >
        Export all
      </StyledTextPrimaryButton>
    </StyledMenuActions>
  );

  const getTitle = (key, title) => {
    if (key === "objective") return currentObjName;
    return title;
  };

  const getValue = (key, value, precision, suffix, prefix) => {
    const currValue = value || 0;
    if (
      ["number_of_holdings", "portfolio_weighted_warf", "warf"].includes(key)
    ) {
      return Number(currValue).toFixed(precision || 0);
    }
    if (key === "objective") return getObjectiveValue(currValue);
    return getFormattedValue(currValue, precision, suffix, prefix);
  };

  const showLastStepDiff = step < resultList.length;
  const showPrevStepDiff = step > 1;
  const renderedCardsConfig = isSellReplaceEnabled
    ? CARDS_CONFIG
    : CARDS_CONFIG.filter(item => item.key !== "number_of_transactions");

  if (isLoading) {
    return (
      <StyledPageContent $inner>
        <Skeleton active />
      </StyledPageContent>
    );
  }

  if (!resultList.length) {
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}>
        <Text type="secondary">There is no built portfolio yet</Text>
      </Empty>
    );
  }

  return (
    <StyledPageContent $inner>
      <StyledPageSection nopadding>
        <StyledTitleArea>
          <StyledPageTitle bold uppercase $hasMargin>
            {`Step ${step}`}
          </StyledPageTitle>
          <ToggleInput
            dataCy="show-charts"
            label="Show Charts"
            value={showCharts}
            onChange={handleToggleCharts}
          />
          <StyledTitleActions>
            {Boolean(allConstraintsValueArr?.length) && step && (
              <StyledTextButton
                data-cy="constraints-values-button"
                type="text"
                onClick={showConstraintValuesModal}
                $capitalize
                style={{ marginRight: "14px" }}
                icon={<StyledBookIcon />}
              >
                Constraints Values
              </StyledTextButton>
            )}
            <StyledButton
              data-cy="download-portfolio-button"
              type="primary"
              onClick={onBtnExportAll}
              size="large"
              $lowercase
              $normal
            >
              Download Portfolio
            </StyledButton>
          </StyledTitleActions>
        </StyledTitleArea>

        <StyledStatisticContainer>
          <ObjectiveStatisticCard
            resultList={resultList}
            step={step}
            showLastStepDiff={showLastStepDiff}
            showPrevStepDiff={showPrevStepDiff}
            overviewChartData={overviewChartData}
            objective={objective}
            currentObjName={currentObjName}
          />
          {renderedCardsConfig.map(cardConfig => (
            <StepStatisticCard
              key={cardConfig.key}
              cardConfig={cardConfig}
              resultList={resultList}
              step={step}
              showLastStepDiff={showLastStepDiff}
              showPrevStepDiff={showPrevStepDiff}
              getTitle={getTitle}
              getValue={getValue}
            />
          ))}
        </StyledStatisticContainer>
      </StyledPageSection>

      {showCharts && (
        <StyledPageSection>
          <StyledSectionRow $columns={3}>
            <StyledSectionColumn>
              <DistributionChartCard chartsData={chartsData} />
            </StyledSectionColumn>
            <StyledSectionColumn>
              <RatingChartCard
                chartsData={chartsData}
                filterProps={filterProps}
              />
            </StyledSectionColumn>
            <StyledSectionColumn>
              <ListCard
                title="Top 10 Loan Agents"
                data={topLoanAgents.data}
                total={`${topLoanAgents.total}%`}
              />
            </StyledSectionColumn>
          </StyledSectionRow>
          <StyledSectionRow>
            <StyledSectionColumn fullwidth>
              <BarChartCard chartsData={chartsData} />
            </StyledSectionColumn>
          </StyledSectionRow>
        </StyledPageSection>
      )}

      <StyledPageSection grow>
        <StyledCard nopadding title="Loans">
          <DataTable
            footerContent={TableFooter}
            {...filterProps}
            onFilterChanged={filterModel =>
              setIsFilteredLoans(!isEmpty(filterModel))
            }
            scope="Step"
          />
        </StyledCard>
      </StyledPageSection>
      <ConstraintsValuesModal
        isOpen={isConstrainValuesModalOpen}
        onClose={() => setConstrainValuesModalOpen(false)}
        allConstraintsValueArr={allConstraintsValueArr}
        selectedGenerationUUID={selectedGenerationUUID}
        portfolioObjectives={objective}
        constraintsData={constraintsData}
      />
    </StyledPageContent>
  );
};

StepDetails.propTypes = {
  step: PropTypes.number.isRequired,
  selectedGenerationUUID: PropTypes.string,
  getPortfolioResultAtStep: PropTypes.func,
  constraintsData: PropTypes.shape(),
  portfolioResultData: PropTypes.shape(),
};

export default StepDetails;
