import { useCallback, useEffect, useState } from "react";
import { InputNumber } from "antd";
import PropTypes from "prop-types";
import { StyledInputWrapper } from "./styles";

const inputDataTypes = {
  money: {
    prefix: "$",
    suffix: "",
    multiplier: 1,
  },
  percent: {
    prefix: "",
    suffix: "%",
    multiplier: 100,
  },
  integer: {
    prefix: "",
    suffix: "",
    multiplier: 1,
  },
  number: {
    prefix: "",
    suffix: "",
    multiplier: 1,
  },
  boolean: {
    prefix: "",
    suffix: "",
    multiplier: 1,
  },
};

const NumberInput = ({
  name,
  attributeName = "",
  onChange,
  type = "number",
  defaultValue = 0,
  status,
  showError = false,
  width,
  ...props
}) => {
  const inputData = inputDataTypes?.[type] ?? inputDataTypes.number;

  const [value, setValue] = useState(defaultValue * inputData.multiplier);

  const handleChange = useCallback(
    val => {
      setValue(val);
      onChange(name, attributeName, val / inputData.multiplier);
    },
    [attributeName, inputData.multiplier, name, onChange],
  );

  useEffect(() => {
    setValue(defaultValue * inputData.multiplier);
  }, [defaultValue, inputData.multiplier]);

  return (
    <StyledInputWrapper
      data-cy={`${name}-${attributeName}`}
      showError={showError && status === "error"}
      width={width}
    >
      <InputNumber
        {...props}
        defaultValue={defaultValue * inputData.multiplier}
        value={value}
        onChange={handleChange}
        addonBefore={inputData.prefix}
        addonAfter={inputData.suffix}
        formatter={newValue => new Intl.NumberFormat("en-US").format(newValue)}
      />
    </StyledInputWrapper>
  );
};

NumberInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  attributeName: PropTypes.string,
  status: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.number,
  showError: PropTypes.bool,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default NumberInput;
