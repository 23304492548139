import * as Sentry from "@sentry/browser";
import {
  isNil,
  get,
  isString,
  isBoolean,
  isUndefined,
  isObject,
  isNull,
  toNumber,
  isNaN,
  includes,
} from "lodash";
import toSentenceCase from "./toSentenceCase";
import divideNumberBy from "./divideNumberBy";
import formatDate from "./formatDate";
import { moneyFormatter, percentWithFractionFormatter } from "./dataFormatters";
import { PERCENT_TEXT_TYPE } from "../constants/constraints";

const supportedTypes = {
  datetime: "datetime",
  currency: "currency",
  percent: "percent",
  string: "string",
  boolean: "boolean",
  number: "number",
  money: "money",
  integer: "integer",
};

const currencyFormatter = props => {
  const { minimumFractionDigits } = props || {};
  const options = {
    style: "currency",
    currency: "USD",
  };
  if (minimumFractionDigits !== undefined) {
    options.minimumFractionDigits = minimumFractionDigits;
  }
  return new Intl.NumberFormat("en-US", options);
};

const percentHandler = (value, needToParse = false) => {
  if (isNil(value)) {
    return "";
  }
  if (isNaN(Number(value))) {
    return value;
  }
  if (needToParse) {
    return percentWithFractionFormatter.format(Number(value));
  }
  if (Number.isInteger(value)) {
    return `${value}%`;
  }
  if (isString(value)) {
    return `${value}%`;
  }
  return `${value.toFixed(2)}%`;
};

export const valueTransformer = {
  currency: (value, withoutDivision, minimumFractionDigits) => {
    const props = {};
    if (minimumFractionDigits !== undefined) {
      props.minimumFractionDigits = minimumFractionDigits;
    }
    if (withoutDivision) {
      return currencyFormatter(props).format(value);
    }
    const numberValue = toNumber(value);
    switch (true) {
      case isNaN(numberValue): {
        return "";
      }
      case numberValue === 0: {
        return "$0";
      }
      case numberValue < 1e3: {
        return currencyFormatter(numberValue).format(value);
      }
      case numberValue < 1e6: {
        return `$${divideNumberBy(numberValue, 1e3)}K`;
      }
      case numberValue < 1e9: {
        return `$${divideNumberBy(numberValue, 1e6)}M`;
      }
      case numberValue < 1e12: {
        return `$${divideNumberBy(numberValue, 1e9)}B`;
      }
      default: {
        return `$${divideNumberBy(numberValue, 1e12)}T`;
      }
    }
  },
  datetime: formatDate,
  percent: percentHandler,
  [PERCENT_TEXT_TYPE]: percentHandler,
  string: value => (value ? value.toString() : ""),
  boolean: value => (value === "True" || value === true ? "Yes" : "No"),
  money: value => {
    if (!value) return "";
    if (isNaN(Number(value))) {
      return value;
    }
    return moneyFormatter.format(value);
  },
  integer: value => (value ? value.toLocaleString("en-US") : ""),
  number: (value = 0, precision = 0) => {
    switch (true) {
      case value < 1e3: {
        return Number(value).toFixed(precision).toString();
      }
      case value < 1e6: {
        return `${divideNumberBy(value, 1e3, precision)}K`;
      }
      case value < 1e9: {
        return `${divideNumberBy(value, 1e6, precision)}M`;
      }
      case value < 1e12: {
        return `${divideNumberBy(value, 1e9, precision)}B`;
      }
      case isNaN(Number(value)): {
        return value;
      }
      default: {
        return `${divideNumberBy(value, 1e12, precision)}T`;
      }
    }
  },
};

const defaultNAText = "N/A";

const parseValue = ({ valuePath, title, value }) => {
  if (isNull(value)) {
    return null;
  }
  const result = {
    title: value?.title || toSentenceCase(title),
    value,
    type: "string",
    isBigValue: value?.isBigValue,
  };
  if (valuePath) {
    result.valuePath = valuePath;
  }
  if (isString(value)) {
    return result;
  }
  if (!isObject(value)) {
    return null;
  }
  if (value.type) {
    result.type = value.type;
  }
  if (isBoolean(value.isNa) && value.isNa) {
    result.value = defaultNAText;
    return result;
  }
  const rawValue = get(value, "value");
  if (isNull(rawValue) || isUndefined(rawValue)) {
    return null;
  }
  const type = get(value, "type");
  if (!includes(Object.keys(supportedTypes), type)) {
    if (process.env.NODE_ENV !== "test") {
      Sentry.captureMessage(
        `Not supported type: ${type} in parseValue. Title: ${title}.`,
      );

      // eslint-disable-next-line no-console
      console.error(
        `Not supported type: ${type} in parseValue. Title: ${title}.`,
      );
    }
    return null;
  }
  result.value = valueTransformer[type](rawValue);
  return result;
};

export default parseValue;
