const exportDataTableCSV = (gridRef, fileName = "", exportAll = false) => {
  const currentColumnDefs = gridRef.current.api.getColumns();

  const columnKeys = currentColumnDefs.filter(
    columnDef => columnDef.userProvidedColDef.field !== "",
  );

  gridRef.current.api.exportDataAsCsv({
    fileName,
    exportedRows: exportAll ? "all" : "filteredAndSorted",
    columnKeys,
  });
};

export default exportDataTableCSV;
