import * as Sentry from "@sentry/browser";

export const moneyIntFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 0,
  maximumFractionDigits: 0,
});

export const percentWith3FractionFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 3,
  maximumFractionDigits: 3,
});

export const percentWithFractionFormatter = new Intl.NumberFormat("en-US", {
  style: "percent",
  minimumFractionDigits: 0,
  maximumFractionDigits: 3,
});

export const twoDecimalFormatter = new Intl.NumberFormat("en-US", {
  style: "decimal",
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const moneyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

export const formatPercentage = {
  format: (num, minimumFractionDigits = 5, maximumFractionDigits = 5) =>
    new Intl.NumberFormat("default", {
      style: "percent",
      minimumFractionDigits,
      maximumFractionDigits,
    }).format(num),
};

const padTo2Digits = num => num.toString().padStart(2, "0");

export const formatDateUSA = {
  format: value => {
    if (!value) {
      return null;
    }
    let date;
    try {
      date = new Date(value);
    } catch (error) {
      Sentry.captureException(error);
      // eslint-disable-next-line no-console
      console.error(error);
      return null;
    }
    return [
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join("/");
  },
};

export const transformFloatingNumbers = value => {
  let maximumFractionDigits = 0;
  if (value <= 2) {
    maximumFractionDigits = 6;
  }

  if (value > 2 && value <= 100) {
    maximumFractionDigits = 3;
  }

  if (value > 100) {
    maximumFractionDigits = 2;
  }

  if (value >= 1000) {
    maximumFractionDigits = 0;
  }

  const formattedValue = new Intl.NumberFormat("en-US", {
    style: "decimal",
    minimumFractionDigits: 0,
    maximumFractionDigits,
  }).format(value);

  return formattedValue;
};
