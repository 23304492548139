/* eslint-disable react/no-array-index-key */
import { useOutletContext } from "react-router";
import { useCallback, useState, useEffect, useMemo } from "react";
import { Skeleton } from "antd";
import { isEmpty, isEqual } from "lodash";
import {
  StyledPageContent,
  StyledPageSection,
  StyledPageSectionActions,
  StyledPageSectionHeader,
  StyledPageSectionSubTitle,
  StyledPageSectionTitle,
} from "../../../styled";
import { StyledChartRow, StyledTabs, StyledChartContainer } from "./styles";
import useTradeSummaryData from "../../../../layout/ResultLayout/context/useTradeSummaryData";
import {
  StyledExcelIcon,
  StyledParametrsIcon,
  StyledHideIcon,
} from "../../../../components/Icons";
import DataTable from "../../../DataFilters/DataTable";
import { TAB_ITEMS } from "./constants";

import {
  StyledTextButton,
  StyledTextPrimaryButton,
} from "../../../../components/Buttons/Button.styled";
import { StyledTableFooter } from "../ResultContent/styles";
import Chart from "./Chart/Chart";
import {
  StyledChartFooter,
  StyledChartTitle,
  StyledChartParametrs,
  StyledChartParametrsRow,
} from "./Chart/styles";
import Checkbox from "../../../../components/Form/Checkbox";
import SolutionDetails from "./SolutionDetails";
import ChartSelectInfo from "./ChartSelectInfo";

const TradeSummaryPage = () => {
  const { workbookId, resultUUID } = useOutletContext();
  const [showInfoSelect, setShowInfoSelect] = useState(false);
  const {
    isChartLoading,
    isDetailsLoading,
    transactionsTableData = {},
    combinedTableData = {},
    selectedValues,
    selectedCollapseIndex,
    selectedCombination,
    generateDetailsLink,
    tabViewSelected,
    setTabViewSelected,
    onBtnExportFiltered,
    onBtnExportAll,
    filteredObjectiveDropdownOptions,
    handleSelectOption,
    selectedDataPoint,
    chartSeries,
    showChartParametrs,
    setShowChartParametrs,
    visibleCharts,
    setVisibleCharts,
    setValuesToCompare,
    valuesToCompare,
    setCompareDataPoint,
    compareDataPoint,
    isMinBest,
    crossTransactionsData,
  } = useTradeSummaryData({
    workbookId,
    resultUUID,
  });
  const [isFilteredCollapsedView, setIsFilteredCollapsedView] = useState(false);
  const [isFilteredExpandedView, setIsFilteredExpandedView] = useState(false);
  const [isFilteredCrossTransactionView, setIsFilteredCrossTransactionView] =
    useState(false);

  useEffect(() => {
    const showInfo =
      compareDataPoint &&
      selectedDataPoint &&
      isEqual(compareDataPoint, selectedDataPoint);

    setShowInfoSelect(showInfo);
  }, [compareDataPoint, selectedDataPoint]);

  const onViewTabChange = useCallback(
    key => setTabViewSelected(key),
    [setTabViewSelected],
  );

  const exportFilteredBtn = useMemo(
    () => (
      <StyledTextPrimaryButton
        type="text"
        icon={<StyledExcelIcon />}
        onClick={onBtnExportFiltered}
      >
        Export filtered
      </StyledTextPrimaryButton>
    ),
    [onBtnExportFiltered],
  );

  const TableFooter = (
    <StyledTableFooter>
      {tabViewSelected === TAB_ITEMS[0].key &&
        isFilteredCollapsedView &&
        exportFilteredBtn}
      {tabViewSelected === TAB_ITEMS[1].key &&
        isFilteredExpandedView &&
        exportFilteredBtn}
      {tabViewSelected === TAB_ITEMS[2].key &&
        isFilteredCrossTransactionView &&
        exportFilteredBtn}

      <StyledTextPrimaryButton
        type="text"
        icon={<StyledExcelIcon />}
        onClick={onBtnExportAll}
      >
        Export all
      </StyledTextPrimaryButton>
    </StyledTableFooter>
  );

  const tabsItems = useMemo(() => {
    const newTabs = [...TAB_ITEMS];
    newTabs[2].disabled = isEmpty(crossTransactionsData?.data);

    if (newTabs[2].disabled && tabViewSelected === TAB_ITEMS[2].key) {
      setTabViewSelected(TAB_ITEMS[0].key);
    }

    return newTabs;
  }, [crossTransactionsData?.data, setTabViewSelected, tabViewSelected]);

  return (
    <StyledPageContent $inner>
      <StyledPageSectionHeader>
        <StyledPageSectionTitle nomargin>Trade Summary</StyledPageSectionTitle>
      </StyledPageSectionHeader>

      <StyledPageSection>
        <StyledChartRow>
          {isChartLoading && <Skeleton active />}
          {!isChartLoading && (
            <>
              <StyledChartContainer>
                <StyledChartTitle>
                  <div>Top Solutions</div>
                  <StyledTextButton
                    $normal
                    $primary
                    type="text"
                    icon={
                      !showChartParametrs ? (
                        <StyledParametrsIcon />
                      ) : (
                        <StyledHideIcon />
                      )
                    }
                    onClick={() =>
                      setShowChartParametrs(prevState => !prevState)
                    }
                  >
                    {showChartParametrs ? "Hide Parameters" : "Show Parameters"}
                  </StyledTextButton>
                </StyledChartTitle>
                {showChartParametrs && (
                  <StyledChartParametrs>
                    {filteredObjectiveDropdownOptions?.map(item => (
                      <StyledChartParametrsRow key={item?.value}>
                        <Checkbox
                          value={visibleCharts?.includes(item?.value)}
                          name={item?.value}
                          color={item?.color}
                          onChange={({ target }) => {
                            const { name, checked } = target;
                            if (visibleCharts?.length < 2 && !checked) {
                              return;
                            }
                            setVisibleCharts(prevState => {
                              if (checked) {
                                return [...prevState, name];
                              }
                              return prevState?.filter(value => value !== name);
                            });
                          }}
                        />
                        <div>{item?.label}</div>
                      </StyledChartParametrsRow>
                    ))}
                  </StyledChartParametrs>
                )}
                {showInfoSelect && (
                  <ChartSelectInfo
                    handleClose={() => setShowInfoSelect(false)}
                  />
                )}
                <Chart
                  handleSelectOption={handleSelectOption}
                  selectedDataPoint={{
                    ...selectedDataPoint,
                    transactions: selectedValues?.transactions,
                  }}
                  compareDataPoint={{
                    ...compareDataPoint,
                    transactions: valuesToCompare?.transactions,
                  }}
                  filteredObjectiveDropdownOptions={
                    filteredObjectiveDropdownOptions
                  }
                  chartSeries={chartSeries}
                  visibleCharts={visibleCharts}
                />
                <StyledChartFooter />
              </StyledChartContainer>
              <SolutionDetails
                selectedValues={selectedValues}
                selectedCollapseIndex={selectedCollapseIndex}
                selectedDataPoint={selectedDataPoint}
                compareDataPoint={compareDataPoint}
                valuesToCompare={valuesToCompare}
                handleSelectOption={handleSelectOption}
                filteredObjectiveDropdownOptions={
                  filteredObjectiveDropdownOptions
                }
                generateDetailsLink={generateDetailsLink}
                onBtnExport={onBtnExportAll}
                setValuesToCompare={setValuesToCompare}
                setCompareDataPoint={setCompareDataPoint}
                isMinBest={isMinBest}
              />
            </>
          )}
        </StyledChartRow>
      </StyledPageSection>

      {selectedCombination && (
        <StyledPageSection>
          <StyledPageSectionHeader>
            <StyledPageSectionSubTitle>
              {`Data Summary: #${+selectedCollapseIndex + 1}`}
            </StyledPageSectionSubTitle>
            <StyledPageSectionActions>
              <StyledTabs
                activeKey={tabViewSelected}
                items={tabsItems}
                onChange={onViewTabChange}
              />
            </StyledPageSectionActions>
          </StyledPageSectionHeader>

          {isDetailsLoading && <Skeleton active />}
          {!isDetailsLoading && (
            <>
              {tabViewSelected === TAB_ITEMS[0].key && (
                <DataTable
                  {...transactionsTableData}
                  scope="tradeSummaryTransactions"
                  onFilterChanged={filterModel =>
                    setIsFilteredCollapsedView(!isEmpty(filterModel))
                  }
                  footerContent={TableFooter}
                />
              )}
              {tabViewSelected === TAB_ITEMS[1].key && (
                <DataTable
                  {...combinedTableData}
                  scope="tradeSummaryCombined"
                  onFilterChanged={filterModel =>
                    setIsFilteredExpandedView(!isEmpty(filterModel))
                  }
                  footerContent={TableFooter}
                />
              )}
              {tabViewSelected === TAB_ITEMS[2].key && (
                <DataTable
                  {...crossTransactionsData}
                  domLayout="autoHeight"
                  scope="crossTransactions"
                  onFilterChanged={filterModel =>
                    setIsFilteredCrossTransactionView(!isEmpty(filterModel))
                  }
                  footerContent={TableFooter}
                />
              )}
            </>
          )}
        </StyledPageSection>
      )}
    </StyledPageContent>
  );
};

export default TradeSummaryPage;
