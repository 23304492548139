import styled, { css } from "styled-components/macro";
import { Select } from "antd";
import {
  blueLight,
  darkGrey2,
  darkGrey3,
  darkGrey4,
  gray2,
  gray5,
  white,
} from "../../constants/colors";
import { maxScreensSizesPx } from "../styled";
import { StyledLink } from "../../components/Typography";

export const StyledPageWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 auto;

  // AG parameters
  --ag-header-background-color: ${white};
  --ag-header-foreground-color: ${darkGrey2};
  --ag-secondary-foreground-color: ${darkGrey2};
  --ag-row-hover-color: ${blueLight};
  --ag-background-color: ${gray2};
  --ag-odd-row-background-color: ${white};
  --ag-border-color: ${gray5};
  --ag-data-color: ${darkGrey4};

  .ag-theme-alpine .ag-root-wrapper {
    border-width: 1px 0;
  }

  .ag-theme-alpine .ag-row {
    border: none;
  }

  .ag-row .ag-cell {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  .ag-cell-value {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 1.4;
  }

  .ag-header-cell-text {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;

    display: flex;
    align-items: center;
    text-transform: uppercase;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1 0 calc(100vh - 300px);
  min-height: 300px;
  height: calc(100vh - 300px);

  ${({ domLayout }) =>
    domLayout === "autoHeight" &&
    css`
      flex: 1 0 auto;
      height: auto;
    `}
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-flow: row nowrap;
  flex: 0 0 auto;
  padding: 0.5rem 1rem;

  button {
    margin-right: 0.5rem;
  }

  > span {
    width: 100%;
  }

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    flex-flow: wrap;
  }
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  margin-left: auto;

  @media (max-width: ${maxScreensSizesPx.mobile}) {
    flex-direction: column;
    margin-left: 0;
  }
`;

export const StyledTableContainer = styled.div`
  padding: 0 2rem;
  width: 100%;
  box-sizing: border-box;
`;

export const StyledResetWrapper = styled.div`
  display: flex;
  row-gap: 12px;
  gap: 10px;
  align-items: baseline;
  margin: 12px 0 12px 0;
  padding: 1rem;
  color: ${darkGrey3};
  font-weight: 600;
  font-size: 14px;
  background-color: ${white};

  ${({ noMargin }) =>
    noMargin &&
    css`
      margin: 0;
    `}

  ${({ noInnerPadding }) =>
    noInnerPadding &&
    css`
      padding: 0;
    `}
`;

export const StyledDeleteContainer = styled.div`
  margin-left: auto;
  margin-right: 8px;
`;

export const StyledActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  margin-bottom: 12px;
  align-items: center;
  background-color: ${white};
`;

export const StyledSearchContainer = styled.div`
  width: 100%;
  padding: 1rem 0 0.5rem 0;
  background-color: ${white};
  .ant-input {
    height: 32px;
    line-height: 22px;
    max-height: 32px;
    box-sizing: border-box;
    padding-bottom: 6px;
  }
  @media (max-width: ${maxScreensSizesPx.tablet}) {
    padding: 0 0 1rem 0;
  }
`;

export const StyledTableHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 24px;
  align-items: center;
  flex-wrap: nowrap;

  @media (max-width: ${maxScreensSizesPx.smallPc}) {
    display: block;
  }
`;

export const StyledTableHeaderContainerInner = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  flex-wrap: nowrap;
  width: 100%;
  gap: 24px;
  align-items: center;

  @media (max-width: ${maxScreensSizesPx.tablet}) {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const StyledTableHeaderContainerSearch = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  flex-wrap: nowrap;
  width: 100%;
  gap: 24px;
  align-items: center;
`;

export const StyledSelect = styled(Select)`
  min-width: 150px;
  .ant-select-selector {
    border: none !important;
    background-color: transparent !important;
    box-shadow: none !important;
    align-items: baseline;
  }
`;

export const StyledFileUploaderTitle = styled.p`
  font-size: 14px !important;
  font-weight: 700;
`;

export const StyledUploaderLink = styled(StyledLink)`
  font-size: 14px;
`;

export const StyledUploaderDescription = styled.p`
  text-align: center;
  margin-top: 5px;
`;
