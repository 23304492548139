import { Tooltip } from "antd";
import { blue } from "../../../../constants/colors";

export const MIN_SIZE = 5;
export const MAX_SIZE = 12;
export const TAB_ITEMS = [
  {
    key: "collapsed",
    label: "Collapsed View",
  },
  {
    key: "expanded",
    label: "Expanded View",
  },
  {
    key: "crossTransactions",
    label: (
      <Tooltip title="Save on trades by automatically matching buy and sell orders for the same security across your multiple portfolios.">
        Cross Transactions
      </Tooltip>
    ),
  },
];

export const DEFAULT_BUBBLE_CHART_OPTIONS = {
  colors: [blue],
  chart: {
    height: 350,
    type: "bubble",
    animations: {
      enabled: false,
    },
    zoom: {
      enabled: false,
      type: "xy",
    },
    toolbar: {
      show: false,
    },
  },
  legend: {
    show: false,
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 0.8,
  },
  xaxis: {
    tickAmount: 10,
    tickPlacement: "on",
    type: "category",
    label: {
      showDuplicates: false,
    },
  },
  yaxis: {},
  tooltip: {
    onDatasetHover: {
      highlightDataSeries: true,
    },
    intersect: true,
  },
  annotations: {
    position: "front",
  },
  plotOptions: {
    bubble: {
      zScaling: true,
    },
  },
};
