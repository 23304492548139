import { StyledUploadSection } from "../../styled";
import { StyledInboxIcon } from "../../../components/Icons";
import {
  StyledFileUploaderTitle,
  StyledUploaderDescription,
  StyledUploaderLink,
} from "../styles";

const FileUploaderContent = () => {
  return (
    <StyledUploadSection>
      <p className="ant-upload-drag-icon">
        <StyledInboxIcon />
      </p>
      <StyledFileUploaderTitle className="ant-upload-text">
        Nothing here at the moment
      </StyledFileUploaderTitle>
      <StyledUploaderDescription className="ant-upload-hint">
        To upload files drag n drop them <br /> or{" "}
        <StyledUploaderLink nonUnderlined>
          click here to upload
        </StyledUploaderLink>
      </StyledUploaderDescription>
    </StyledUploadSection>
  );
};

FileUploaderContent.propTypes = {};

export default FileUploaderContent;
