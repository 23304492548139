import {
  StyledPhoneIcon,
  StyledEmailIcon,
  StyledPinIcon,
  StyledFooterLogoIcon,
} from "../Icons";
import {
  StyledFooter,
  StyledLogo,
  StyledCopyrightText,
  StyledCopyrightBlock,
  StyledTitle,
  StyledLink,
  StyledInfoRow,
} from "./Footer.styled";

const data = {
  emailus: "info@loan-hunter-solutions.com",
  callUs: "+1-646-303-7385",
  ourLocation: "99 Wall Street, Suite 2501, New York, NY 10005 USA",
  termsLink: {
    title: "Terms and Conditions of Use",
    path: "/terms-and-conditions",
  },
  copyrightText: "Loan Hunter Solutions",
  copyrightDate: `${new Date().getFullYear()} Loan Hunter Holdings, LLC`,
  policiesLink: {
    title: "Policies and Disclosures",
    path: "/policies-and-disclosures",
  },
};

const Footer = () => {
  return (
    <StyledFooter>
      <div>
        <StyledLogo>
          <StyledFooterLogoIcon />
        </StyledLogo>
        <StyledCopyrightBlock>
          <div>
            <StyledCopyrightText>{data.copyrightText}</StyledCopyrightText>
          </div>
          <div>&copy;{data.copyrightDate}</div>
        </StyledCopyrightBlock>
      </div>
      <div>
        <StyledTitle>Important Information</StyledTitle>
        <StyledLink
          href={`${window.location.origin}${data.policiesLink.path}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {data.policiesLink.title}
        </StyledLink>
        <StyledLink
          href={`${window.location.origin}${data.termsLink.path}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          {data.termsLink.title}
        </StyledLink>
      </div>
      <div>
        <StyledTitle>Contact Us</StyledTitle>
        <StyledInfoRow>
          <StyledPhoneIcon />
          <span>{data.callUs}</span>
        </StyledInfoRow>
        <StyledInfoRow>
          <StyledEmailIcon />
          <StyledLink
            href={`mailto: ${data.emailus}`}
            target="_blank"
            noMargin
            rel="noreferrer noopener"
          >
            {data.emailus}
          </StyledLink>
        </StyledInfoRow>
      </div>
      <div>
        <StyledTitle>address</StyledTitle>
        <StyledInfoRow>
          <StyledPinIcon />
          <span>{data.ourLocation}</span>
        </StyledInfoRow>
      </div>
    </StyledFooter>
  );
};

export default Footer;
