import { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

import { getPortfolioArguments } from "../../../api/endpoints/portfolios";
import { PageContext } from "../../../layout/ResultLayout/context";
import ModalDialog from "../../../components/Modals/ModalDialog";
import { handleErrorMessages } from "../../../utils/messages";
import {
  StyledModalButtonsContainer,
  StyledModalPrimaryText,
} from "../../../components/Modals/ModalDialog.styled";
import StyledButton from "../../../components/Buttons/Button.styled";

const ConfirmCopyModal = ({
  isOpen = false,
  onClose,
  constraintsData,
  setIsLoading = () => {},
  loading = false,
  isEnabledSetting,
}) => {
  const [selectedPortfolioConstraints, setSelectedPortfolioConstraints] =
    useState({});
  const { portfolioList, setPortfolioList } = useContext(PageContext);

  const { selectedPortfolio: currentPortfolio, onUpdateAllConstraints } =
    constraintsData;

  const portfoliosToAplly = portfolioList?.filter(
    item => item?.uuid !== currentPortfolio?.uuid,
  );

  const handleApplyConstraints = () => {
    setIsLoading(true);
    Promise.all(
      portfoliosToAplly?.map((portfolio, index) => {
        const updatedPortfolio = portfolioList?.find(
          item => item?.uuid === portfolio?.uuid,
        );
        return onUpdateAllConstraints({
          portfolioUUID: portfolio?.uuid,
          name: updatedPortfolio?.name,
          data: selectedPortfolioConstraints,
          isLast: index === portfoliosToAplly.length - 1,
          setLoading: setIsLoading,
          handleSuccess: () => {
            onClose();
            message.success({
              content: "Constraints updated",
              className: "exos-message",
            });
          },
        });
      }),
    )
      .then(() => {
        setPortfolioList(
          portfolioList.map(portfolio => ({
            ...portfolio,
            isEnabled: isEnabledSetting.isEnabled,
          })),
        );
      })
      .catch(error => handleErrorMessages(error));
  };

  useEffect(() => {
    if (!currentPortfolio?.uuid || !isOpen) return;
    getPortfolioArguments(currentPortfolio?.uuid)
      .then(data => setSelectedPortfolioConstraints(data))
      .catch(error => handleErrorMessages(error));
  }, [currentPortfolio, isOpen]);

  return (
    <ModalDialog
      title="Copy constraints"
      isOpen={isOpen}
      onClose={onClose}
      width="auto"
    >
      <StyledModalPrimaryText>
        You are trying to propagate settings from {currentPortfolio?.name} to
        all portfolios. Are you sure you want to do that?
      </StyledModalPrimaryText>
      <StyledModalButtonsContainer>
        <StyledButton onClick={onClose} type="secondary" $autoWidth>
          Cancel
        </StyledButton>
        <StyledButton
          onClick={handleApplyConstraints}
          $autoWidth
          type="primary"
          disabled={!(currentPortfolio && portfoliosToAplly.length) || loading}
          data-cy="apply-copied-constraints"
        >
          {loading ? <LoadingOutlined /> : "Apply"}
        </StyledButton>
      </StyledModalButtonsContainer>
    </ModalDialog>
  );
};

ConfirmCopyModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  constraintsData: PropTypes.shape(),
  loading: PropTypes.bool,
  setIsLoading: PropTypes.func,
  isEnabledSetting: PropTypes.shape(),
};

export default ConfirmCopyModal;
