import PropTypes from "prop-types";
import { Tooltip } from "antd";
import {
  StyledAccordionMenuItem,
  StyledMenuItemContent,
  StyledMenuItemExtra,
  StyledMenuItemIcon,
  StyledMenuTitle,
  StyledMenuValue,
} from "./styles";
import {
  StyledEmojiEventIcon,
  StyledStarIcon,
} from "../../../../components/Icons";
import { goldLight } from "../../../../constants/colors";
import { getObjectiveTitle, getObjectiveTooltipTitle } from "./helpers";

const SideAccordionMenuItem = ({
  icon,
  step,
  title,
  objectivesDetails,
  objective,
  extraInfo,
  onClick = () => {},
  isFeatured = false,
  isSameValue = false,
  isActive = false,
  showIcon = false,
  header = false,
  isLast = false,
  dataCy = "menu-item",
}) => {
  const isLighter = !isLast && isSameValue;
  const objectivesDetailsTooltipTitle = getObjectiveTooltipTitle(
    objectivesDetails,
    objective,
  );
  const objectivesDetailsTitle = getObjectiveTitle(objectivesDetails, {
    isLighter,
  });

  return (
    <StyledAccordionMenuItem
      data-cy={isActive ? `${dataCy}-active` : dataCy}
      active={isActive}
      onClick={() => onClick(step)}
      header={header}
    >
      <Tooltip placement="right" title={objectivesDetailsTooltipTitle}>
        <StyledMenuItemIcon showIcon={showIcon}>{icon}</StyledMenuItemIcon>
      </Tooltip>
      <StyledMenuItemContent showIcon={showIcon}>
        <StyledMenuTitle header={header}>{title}</StyledMenuTitle>
        <Tooltip placement="right" title={objectivesDetailsTooltipTitle}>
          <StyledMenuValue isLighter={isLighter}>
            {objectivesDetailsTitle}
          </StyledMenuValue>
        </Tooltip>
        {isLast && <StyledEmojiEventIcon color={goldLight} />}
        {isFeatured && <StyledStarIcon color={goldLight} />}
      </StyledMenuItemContent>
      {extraInfo && <StyledMenuItemExtra>{extraInfo}</StyledMenuItemExtra>}
    </StyledAccordionMenuItem>
  );
};

SideAccordionMenuItem.propTypes = {
  isActive: PropTypes.bool,
  isSameValue: PropTypes.bool,
  isFeatured: PropTypes.bool,
  showIcon: PropTypes.bool,
  header: PropTypes.bool,
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  step: PropTypes.number,
  title: PropTypes.string,
  objectivesDetails: PropTypes.arrayOf(PropTypes.shape()),
  extraInfo: PropTypes.oneOfType([PropTypes.shape(), PropTypes.string]),
  onClick: PropTypes.func,
  isLast: PropTypes.bool,
  dataCy: PropTypes.string,
  objective: PropTypes.number,
};

export default SideAccordionMenuItem;
