/* eslint-disable import/prefer-default-export */

import { toUpper } from "lodash";
import { Fragment } from "react";
import {
  chartViolet,
  chartOrange,
  chartGreen,
  chartYellow,
  chartBlue,
  chartDarkBlue,
  chartGrey,
  chartPink,
  chartBrown,
  chartAccentGreen,
} from "../../../../constants/colors";
import { transformFloatingNumbers } from "../../../../utils/dataFormatters";
import {
  StyledObjectivesDetailsTooltipWrapper,
  StyledObjectiveTag,
  StyledObjectiveTitleDivider,
  StyledObjectiveTooltipTitleItem,
} from "./styles";

const insertColor = (arr, index, color) => {
  return [...arr.slice(0, index), color, ...arr.slice(index)];
};

const getColor = key => {
  switch (key) {
    case "objective":
      return chartGreen;
    case "warf":
      return chartViolet;
    case "loan_maturity":
      return chartOrange;
    case "bid_ask_spread":
      return chartYellow;
    case "was":
      return chartBlue;
    default:
      return chartOrange;
  }
};

export const getChartColors = chartKeys => {
  let chartColors = [
    chartBrown,
    chartDarkBlue,
    chartPink,
    chartGrey,
    chartAccentGreen,
  ];

  chartKeys?.forEach((key, index) => {
    chartColors = insertColor(chartColors, index, getColor(key));
  });

  if (chartKeys?.length === 1) {
    chartColors = [chartOrange];
  }

  return chartColors;
};

export const getTag = (
  { name, value },
  { isLighter, isRounded } = { isLighter: false, isRounded: true },
) => {
  const color = getColor(name);
  const newValue = isRounded ? transformFloatingNumbers(value) : value;

  return (
    <StyledObjectiveTag key={name} color={color} isLighter={isLighter}>
      {newValue}
    </StyledObjectiveTag>
  );
};

export const getObjectiveTitle = (objectivesDetails, { isLighter } = {}) => {
  let isLast = false;

  return objectivesDetails?.map(({ name, value }, index) => {
    if (index === objectivesDetails.length - 1) {
      isLast = true;
    }

    return (
      <Fragment key={name}>
        {getTag({ name, value }, { isLighter, isRounded: true })}
        {isLast ? null : (
          <StyledObjectiveTitleDivider isLighter={isLighter}>
            ;&nbsp;
          </StyledObjectiveTitleDivider>
        )}
      </Fragment>
    );
  });
};

export const getObjectiveTooltipTitle = (objectivesDetails, objective) => {
  const isComposedFunc = objectivesDetails.length > 1;

  return (
    <StyledObjectivesDetailsTooltipWrapper>
      {isComposedFunc && (
        <StyledObjectiveTooltipTitleItem>
          {toUpper("Objective Function")} <br />
          {getTag(
            { name: "objective", value: objective },
            { isLighter: false, isRounded: !isComposedFunc },
          )}
        </StyledObjectiveTooltipTitleItem>
      )}

      {objectivesDetails?.map(({ name, value }) => {
        return (
          <StyledObjectiveTooltipTitleItem key={name}>
            {toUpper(name.replace("_", " "))} <br />
            {getTag({ name, value }, { isLighter: false, isRounded: true })}
          </StyledObjectiveTooltipTitleItem>
        );
      })}
    </StyledObjectivesDetailsTooltipWrapper>
  );
};

export const getYearSuffix = yearNumber => {
  const suffix = yearNumber <= 1 ? "yr" : "yrs";
  return `${yearNumber} ${suffix}`;
};
