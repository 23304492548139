import axiosInstance from "../axiosInstance";

export const getResultViews = async ({ workbookId, page = 1, filter = {} }) => {
  const params = { page, ...filter };
  const response = await axiosInstance({
    url: `/workbooks/${workbookId}/results/`,
    params,
    paramsSerializer: {
      indexes: null,
    },
  });
  return response?.data;
};

export const createWorkbookResultView = async (workbookId, fileForm) => {
  const response = await axiosInstance({
    url: `/workbooks/${workbookId}/upload/`,
    method: "post",
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: fileForm,
  });
  return response?.data;
};

export const buildPortfolioResult = async resultUUID => {
  const response = await axiosInstance({
    url: `/results/${resultUUID}/start/`,
    method: "post",
  });
  return response?.data;
};

export const getResultByUUID = async resultUUID => {
  const response = await axiosInstance({
    url: `/results/${resultUUID}/`,
  });
  return response?.data;
};

export const deleteResultByUUID = async resultUUID => {
  const response = await axiosInstance({
    url: `/results/${resultUUID}/`,
    method: "delete",
  });
  return response?.data;
};

export const cancelPortfolioResult = async resultUUID => {
  const response = await axiosInstance({
    url: `/results/${resultUUID}/cancel/`,
    method: "post",
  });
  return response?.data;
};

export const getPortfolioResultProgress = async resultUUID => {
  const response = await axiosInstance({
    url: `/results/${resultUUID}/progress/`,
  });
  return response?.data;
};

export const downloadPortfolioResult = async resultUUID => {
  const response = await axiosInstance(
    {
      url: `/results/${resultUUID}/download/`,
    },
    {
      responseType: "blob",
    },
  );
  return response?.data;
};

export const getTradeSummaryInfo = async resultUUID => {
  const response = await axiosInstance({
    url: `/results/${resultUUID}/trade-summary/`,
  });
  return response?.data;
};

export const getTradeSummaryExpandedTransactions = async (genIds = []) => {
  const response = await axiosInstance({
    url: `/trade-summary/expanded-transactions/${genIds.join(",")}/`,
  });
  return response?.data;
};

export const getTradeSummaryCollapsedTransactions = async (genIds = []) => {
  const response = await axiosInstance({
    url: `/trade-summary/collapsed-transactions/${genIds.join(",")}/`,
  });
  return response?.data;
};

export const getTradeCrossTransactions = async (genIds = []) => {
  const response = await axiosInstance({
    url: `/trade-summary/cross-transactions/${genIds.join(",")}/`,
  });
  return response?.data;
};

export const getTradeSummaryCollapsedTransactionsDownload = async (
  genIds = [],
) => {
  const response = await axiosInstance({
    url: `/trade-summary/collapsed-transactions/${genIds.join(",")}/download/`,
  });
  return response?.data;
};

export const getTradeSummaryExpandedTransactionsDownload = async (
  genIds = [],
) => {
  const response = await axiosInstance({
    url: `/trade-summary/expanded-transactions/${genIds.join(",")}/download/`,
  });
  return response?.data;
};

export const getTradeSummaryTransactionsDownload = async (genIds = []) => {
  const response = await axiosInstance({
    url: `/trade-summary/combined-generations/${genIds.join(",")}/download/`,
  });
  return response?.data;
};
