/* eslint-disable react/prop-types */
import { useCallback, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { Input } from "antd";
import { isEmpty } from "lodash";
import {
  StyledContainer,
  StyledFooter,
  StyledPageWrapper,
  StyledSearchContainer,
  StyledTableHeaderContainer,
  StyledTableHeaderContainerInner,
  StyledTableHeaderContainerSearch,
} from "../styles";
import { handleErrorMessages } from "../../../utils/messages";
import {
  StyledSectionContent,
  StyledSectionText,
  StyledSectionTitle,
} from "../../styled";
import ResetFilter from "../ResetFilter/ResetFilter";
import useIsMobile from "../../../hooks/useIsMobile";

const AgGridContainer = ({
  data,
  gridRef,
  defaultColDef,
  processedColumnDefs,
  footerContent,
  scope,
  noInnerPadding,
  getRowStyle,
  showSearch = false,
  type = "",
  bulkUpdateSelect,
  onRowSelected,
  onFilterChanged,
  domLayout,
  onSortChanged,
  ...restProps
}) => {
  const [isFilterActive, setIsFilterActive] = useState(false);
  const isMobile = useIsMobile();

  const filterModelKey = scope ? `filterModel${scope}` : "filterModel";
  const columnStateKey = scope ? `columnState${scope}` : "columnState";

  const clearFilters = useCallback(() => {
    gridRef.current.api.setFilterModel(null);
  }, [gridRef]);

  const handleFilterChanged = params => {
    const filterModel = params.api.getFilterModel();
    setIsFilterActive(!isEmpty(filterModel));
    sessionStorage.setItem(filterModelKey, JSON.stringify(filterModel));
    onFilterChanged?.(filterModel);
  };

  const onSaveGridColumnState = params => {
    const filterModel = params.columnApi.getColumnState();
    sessionStorage.setItem(columnStateKey, JSON.stringify(filterModel));
    onSortChanged?.(params);
  };

  const onFirstDataRendered = params => {
    try {
      const columnState = sessionStorage.getItem(columnStateKey);
      const filterModel = sessionStorage.getItem(filterModelKey);

      if (columnState) {
        const parsedColumnState = JSON.parse(columnState);

        params.columnApi.applyColumnState({
          state: parsedColumnState,
          applyOrder: true,
        });
      }

      if (filterModel) {
        const parsedFilterModel = JSON.parse(filterModel);
        setIsFilterActive(!isEmpty(parsedFilterModel));
        params.api.setFilterModel(parsedFilterModel);
      }
    } catch (error) {
      handleErrorMessages(error);
    }
  };

  const handleTableFilter = event => {
    gridRef.current.api.setQuickFilter(event?.target?.value);
  };

  return (
    <StyledPageWrapper>
      <StyledContainer domLayout={domLayout} className="ag-theme-alpine">
        {type === "data-filters" && (
          <StyledSectionContent nomargin>
            <StyledSectionTitle>Data Table</StyledSectionTitle>
            <StyledTableHeaderContainer>
              <StyledTableHeaderContainerInner>
                <StyledSectionText style={{ lineHeight: "32px" }}>
                  Select loans with checkbox to secure their appearance in final
                  portfolio
                </StyledSectionText>
                <StyledTableHeaderContainerSearch>
                  {showSearch && (
                    <StyledSearchContainer>
                      <Input
                        placeholder="search"
                        onChange={handleTableFilter}
                        id="filter-text-box"
                      />
                    </StyledSearchContainer>
                  )}
                  {!!bulkUpdateSelect && (
                    <StyledSearchContainer>
                      {bulkUpdateSelect}
                    </StyledSearchContainer>
                  )}
                </StyledTableHeaderContainerSearch>
              </StyledTableHeaderContainerInner>
              {isFilterActive && (
                <ResetFilter
                  clearFilters={clearFilters}
                  noInnerPadding={noInnerPadding}
                  noMargin
                  showText={isMobile}
                />
              )}
            </StyledTableHeaderContainer>
          </StyledSectionContent>
        )}
        {showSearch && type !== "data-filters" && (
          <StyledSearchContainer>
            <Input
              placeholder="search"
              onChange={handleTableFilter}
              id="filter-text-box"
            />
          </StyledSearchContainer>
        )}
        <AgGridReact
          rowData={data}
          ref={gridRef}
          defaultColDef={defaultColDef}
          groupDisplayType="groupRows"
          rowGroupPanelShow="always"
          groupSelectsChildren
          rowSelection="multiple"
          suppressRowClickSelection
          onFilterChanged={handleFilterChanged}
          onSortChanged={onSaveGridColumnState}
          onFirstDataRendered={onFirstDataRendered}
          getRowStyle={getRowStyle}
          onRowSelected={onRowSelected}
          data-cy="data-filtering-table"
          domLayout={domLayout}
          {...restProps}
          columnDefs={processedColumnDefs}
        />
      </StyledContainer>

      {footerContent && <StyledFooter>{footerContent}</StyledFooter>}
    </StyledPageWrapper>
  );
};

export default AgGridContainer;
