import PropTypes from "prop-types";
import { Alert, Form } from "antd";
import { useCallback, useLayoutEffect, useMemo, useRef, useState } from "react";
import ModalDialog from "../../../components/Modals/ModalDialog";
import TextInput from "../../../components/Form/TextInput";
import { StyledCloseIcon } from "../../../components/Icons";
import { StyledTextButton } from "../../../components/Buttons/Button.styled";
import { StyledEditConstraintItem, StyledEditConstraintItems } from "../styles";
import useAutoFocus from "../../../hooks/useAutoFocus";

export const hasMultipleCopies = (arr, checkedValue) => {
  let count = 0;

  for (const item of arr) {
    if (checkedValue.trim() === item.value.trim()) {
      count += 1;
    }
  }

  return count >= 2;
};

const makeConstraintsWithStatus = arr =>
  arr.map(newPrevVal => {
    if (hasMultipleCopies(arr, newPrevVal.value)) {
      return { value: newPrevVal.value, status: "error" };
    }

    return { value: newPrevVal.value };
  });

const EditConstraintQueryModal = ({
  isOpen = false,
  defaultValue,
  onClose,
  onChange,
  name,
  attributeName = "",
}) => {
  const addInputRef = useAutoFocus();
  const [newValue, setNewValue] = useState("");
  const [value, setValue] = useState(
    () => defaultValue?.map(val => ({ value: val })) ?? [],
  );
  const [form] = Form.useForm();
  const firstUpdate = useRef(true);

  const isValid = useMemo(() => {
    return !value.some(val => val.status === "error");
  }, [value]);

  const handleClose = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }

    if (isValid) {
      onChange(
        name,
        attributeName,
        value.reduce((acc, val) => {
          if (val.value) {
            acc.push(val.value.trim());
            return acc;
          }

          return acc;
        }, []),
      );
    }
  }, [attributeName, isValid, name, onChange, value]);

  const handleDelete = useCallback(index => {
    setValue(prev => {
      const newPrev = [...prev];
      newPrev.splice(index, 1);

      return makeConstraintsWithStatus(newPrev);
    });
  }, []);

  const handleInputChange = useCallback((event, index = null) => {
    const val = event.target.value;

    setValue(prev => {
      const newPrev = [...prev];
      const newIndex = index ?? newPrev.length;

      newPrev[newIndex] = { value: val };

      return makeConstraintsWithStatus(newPrev);
    });
  }, []);

  const handleEnterNewValue = useCallback(
    event => {
      const val = event.target.value.trim();

      if (event.key === "Enter" && !!val) {
        handleInputChange(event);
        setNewValue("");
      }
    },
    [handleInputChange],
  );

  return (
    <ModalDialog isOpen={isOpen} title="Edit Constraint" onClose={handleClose}>
      <StyledEditConstraintItems>
        {!isValid && (
          <StyledEditConstraintItem>
            <Alert message="The values should be unique." type="error" />
          </StyledEditConstraintItem>
        )}
        {value?.map((val, index) => (
          <StyledEditConstraintItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
          >
            <TextInput
              status={val.status}
              value={val.value}
              onChange={event => handleInputChange(event, index)}
            />
            <StyledTextButton
              $transparent
              $noPadding
              $autoHeight
              shape="circle"
              icon={<StyledCloseIcon />}
              onClick={() => handleDelete(index)}
            />
          </StyledEditConstraintItem>
        ))}

        <StyledEditConstraintItem>
          <TextInput
            value={newValue}
            ref={addInputRef}
            placeholder="Type a new value and press Enter..."
            onKeyDown={handleEnterNewValue}
            onChange={event => setNewValue(event.target.value)}
          />
        </StyledEditConstraintItem>
      </StyledEditConstraintItems>
    </ModalDialog>
  );
};

EditConstraintQueryModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  defaultValue: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string.isRequired,
  attributeName: PropTypes.string,
};

export default EditConstraintQueryModal;
