import { useCallback, useState } from "react";
import { Pagination, Skeleton } from "antd";
import { useNavigate, useOutletContext } from "react-router";
import { isEmpty } from "lodash";

import StyledTable from "../../../components/Table";
import ActionsHeader from "../../Shared/ActionsHeader";
import {
  StyledPage,
  StyledPageContent,
  StyledPageHeaderTitle,
  StyledPageHeaderTitleContainer,
  tableScrollWidth,
} from "../../styled";
import useResultsData from "../context/useResultsData";
import usePortfolioData from "../../../layout/ResultLayout/context/usePortfolioData";
import NotFound from "../../ErrorPages/NotFound";
import {
  StyledTableInfo,
  StyledTablePagination,
  StyledTableFooter,
} from "../../Workbooks/styles";
import {
  StyledDeleteContainer,
  StyledActionsContainer,
} from "../../DataFilters/styles";
import { StyledTextButton } from "../../../components/Buttons/Button.styled";
import DeleteResultModal from "../context/DeleteResultModal";
import { deleteResult, deleteResults } from "../../../api/endpoints/portfolios";
import {
  handleErrorMessages,
  showMessageSuccess,
} from "../../../utils/messages";
import {
  StyledHomeIcon,
  StyledTrashOutlineIcon,
} from "../../../components/Icons";
import { PROGRESS_STATES } from "../../../constants/constraints";
import ResetFilter from "../../DataFilters/ResetFilter/ResetFilter";

const Results = () => {
  const navigate = useNavigate();
  const {
    isLoading,
    workbookResults,
    paginationData,
    onPageChange,
    getWorkbookResults,
    currentWorkbook,
    isNotFound,
  } = useOutletContext();

  const { onClickPortfolioBuild, startBuildLoading } = usePortfolioData({
    portfolioUUID: null,
    resultUUID: null,
  });

  const [selectedRowIds, setSelectedRowIds] = useState([]);

  const {
    columns,
    clearFilters,
    filters,
    showResetBtn,
    setDeleteResultUUID,
    deleteResultUUID,
  } = useResultsData({
    onClickPortfolioBuild,
    startBuildLoading,
  });

  const handleDeleteResult = async () => {
    try {
      await deleteResult(deleteResultUUID);
      setDeleteResultUUID("");
      showMessageSuccess({
        text: `${deleteResultUUID} result was deleted`,
      });
      getWorkbookResults({ page: 1 });
    } catch (err) {
      handleErrorMessages(err);
      setDeleteResultUUID("");
    }
  };

  const handleDeleteResults = async () => {
    try {
      await deleteResults(selectedRowIds);
      showMessageSuccess({
        text: "Results were deleted",
      });
      getWorkbookResults({ page: 1 });
      setSelectedRowIds([]);
    } catch (err) {
      handleErrorMessages(err);
    }
  };

  const showActions =
    (!isEmpty(filters) && showResetBtn) || Boolean(selectedRowIds?.length);

  const config = [
    {
      icon: <StyledHomeIcon />,
      onClick: () => navigate("/"),
    },
    {
      label: currentWorkbook?.name,
    },
  ];

  const TableFooter = useCallback(
    () =>
      paginationData?.total > workbookResults?.length && (
        <StyledTableFooter>
          <StyledTableInfo>{`${workbookResults?.length} out of ${paginationData?.total}`}</StyledTableInfo>
          <StyledTablePagination>
            <Pagination {...paginationData} onChange={onPageChange} />
          </StyledTablePagination>
        </StyledTableFooter>
      ),
    [onPageChange, paginationData, workbookResults?.length],
  );

  if (isNotFound) {
    return <NotFound />;
  }

  return (
    <StyledPage grow>
      <ActionsHeader breadcrumbsConfig={config} isRightSideChildren />
      <StyledPageHeaderTitleContainer>
        <StyledPageHeaderTitle>{currentWorkbook?.name}</StyledPageHeaderTitle>
      </StyledPageHeaderTitleContainer>
      <StyledPageContent $noPadding>
        {isLoading && <Skeleton active />}
        {!isLoading && showActions && (
          <StyledActionsContainer>
            {!isEmpty(filters) && showResetBtn && (
              <ResetFilter noMargin clearFilters={clearFilters} />
            )}
            <StyledDeleteContainer>
              {Boolean(selectedRowIds?.length) && (
                <StyledTextButton
                  onClick={handleDeleteResults}
                  $error
                  type="text"
                  $iconOnly
                  icon={<StyledTrashOutlineIcon />}
                >
                  delete selected
                </StyledTextButton>
              )}
            </StyledDeleteContainer>
          </StyledActionsContainer>
        )}
        {!isLoading && (
          <StyledTable
            columns={columns}
            dataSource={workbookResults}
            scroll={{ x: tableScrollWidth }}
            rowKey={({ uuid }) => uuid}
            pagination={false}
            footer={TableFooter}
            defaultSelectedRowKeys={selectedRowIds}
            handleSelectRow={value => setSelectedRowIds(value)}
            onRow={record => {
              const { workbookUuid, uuid, status, amountOfPortfolios } = record;
              const hasData = Boolean(amountOfPortfolios);
              const urlLinkData =
                status === PROGRESS_STATES.draft
                  ? `/workbooks/${workbookUuid}/results/${uuid}/constraints`
                  : `/workbooks/${workbookUuid}/results/${uuid}`;
              const urlLinkNoData = `/workbooks/${workbookUuid}/results/${uuid}/filtering`;
              const urlLink = hasData ? urlLinkData : urlLinkNoData;
              return {
                onClick: () => {
                  navigate(urlLink);
                },
              };
            }}
          />
        )}
      </StyledPageContent>
      <DeleteResultModal
        isOpen={Boolean(deleteResultUUID)}
        resultName={deleteResultUUID}
        onClose={() => setDeleteResultUUID("")}
        onDelete={handleDeleteResult}
      />
    </StyledPage>
  );
};

export default Results;
